
import CloudFun, { computed, defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue"
import { VxeFormProps } from "vxe-table";
import CheckBoxList from "@/cloudfun/components/CheckBoxList.vue"

export default defineComponent({
  components:{
    Grid,
    CheckBoxList,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    var printColumns = [
      { field: 'Name' }, 
      { field: 'Description' },      
    ]

    const gridOptions: GridOptions = {
      title: '角色',
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: { sheetName: '角色清單', columns: printColumns, modes: [ 'current', 'selected', 'all' ] },
      exportConfig: { filename: '角色清單', type: 'csv', types: [ 'html', 'csv' ], mode: 'all', modes: [ 'current', 'selected', 'all' ], columns: printColumns },
      columns: [{ 
          field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, 
          editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'filter-name' } 
        }, { 
          field: 'Description', title: '說明', showHeaderOverflow: true, showOverflow: true, resizable: false, 
          editRender: { name: '$input', immediate: true, attrs: { type: 'text' } } 
        }, { 
          field: "PermissionValues", visible: false,
        }],
      promises: {
        query: model ? (params) => model.dispatch('role/query', params) : undefined,
        queryAll: model ? () => model.dispatch('role/query') : undefined,
        save: model ? (params) => model.dispatch('role/save', params) : undefined
      },
      modalConfig: { width: 550, height: 400 }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: 'right',
      items: [
        {
          field: 'Name', title: '名稱', span: 24, 
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } },
        }, {
          field: 'Description', title: '說明', span: 24, 
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } } 
        }, {
          title: '權限', span: 24, slots: { default: 'form-permissions' }
        }, {
          span: 24, align: "right", 
          itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確定', status: 'primary' } }, { props: { type: 'reset', content: '重置' } }] }
        }
      ], 
      rules: { 
        Name: [{ type: 'string', required: true }],
      }
    }

    const permissionItems = computed(() => {
      let items: any[] = [];
      const groupItems: any = {};
      for (const permission of Object.values(model?.enums.SystemPermission || {})) {
        if (permission.GroupName != null) {
          if (!groupItems[permission.GroupName]) groupItems[permission.GroupName] = [];
          groupItems[permission.GroupName].push({ name: permission.Name, value: permission.Value });
        } else items.push({ name: permission.Name, value: permission.Value });
      }
      const groupNames = Object.keys(groupItems);
      if (groupNames.length > 0) {
        if (items.length > 0) groupItems[''] = items;
        items = []; // clear items
        groupNames.forEach(name => {
          items.push({ name: name, items: groupItems[name] });
        });
      }
      return items;
    });

    return {
      grid,
      gridOptions,
      formOptions,
      permissionItems,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide');
    }
  }
});
