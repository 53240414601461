
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, url } from "@vuelidate/validators";
import context from "@cloudfun/core";

export default defineComponent({
  setup() {
    const application = context.root;
    const configuration = reactive({
      PortalDefaultTitle: "",
      PortalDefaultMetaKeywords: "",
      PortalDefaultMetaDescription: "",
      ServicePhone: "",
      CompanyFax: "",
      CompanyAddress: "",
      ServiceEmail: "",
      Facebook: "",
      Instagram: "",
      Twitter: "",
      LINE: "",
      TrackingCode: "",
      AdminTitle: "",
      Principal: "",
    });

    const rules = {
      PortalDefaultTitle: { required },
      PortalDefaultMetaKeywords: { required },
      PortalDefaultMetaDescription: { required },
      ServicePhone: {},
      CompanyFax: {},
      CompanyAddress: {},
      ServiceEmail: { email },
      Facebook: { url },
      Instagram: { url },
      Twitter: { url },
      LINE: { url },
      TrackingCode: {},
      Principal: {},
      AdminTitle: { required },
    };

    const validate = useVuelidate(rules, toRefs(configuration));

    const save = () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        context.send("warning", {
          subject: "資料有誤",
          content: "資料驗證失敗，請依提示進行修正後再試一次",
        });
      } else {
        application?.model.dispatch("configuration/update", configuration).then(
          () => { // success
            context.send("info", {
              subject: "更新成功",
              content: "系統配置已更新"
            });
          }, 
          failure => {
            context.send("error", {
              subject: "更新失敗",
              content: failure.message
            })
          }
        );
      }
    };

    onMounted(() => {
      application?.model.dispatch("configuration/read").then(value => {
        Object.assign(configuration, value);
      });
    });

    return {
      configuration,
      validate,
      save,
    };
  },
});
