<template>
  <router-view />
</template>

<style>
.vxe-header--column .vxe-cell--sort .vxe-sort--asc-btn {
  border-color: unset !important;
}
.vxe-header--column .vxe-cell--sort .vxe-sort--desc-btn {
  border-color: unset !important;
}
.vxe-header--column .vxe-cell--filter .vxe-filter--btn {
  border-color: unset !important;
}
.ck-tooltip .ck-tooltip__text {
  color: #fff !important;
}
</style>