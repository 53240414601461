<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu :admin-title="adminTitle" />
    <!-- BEGIN: Top Bar -->
    <div
      class="border-b border-theme-24 -mt-10 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 pt-3 md:pt-0 mb-10"
    >
      <div class="top-bar-boxed flex items-center">
        <!-- BEGIN: Logo -->
        <router-link
          to="/top-menu"
          tag="a"
          class="-intro-x hidden md:flex"
        >
          <img
            :alt="adminTitle"
            class="w-6"
            src="@/assets/images/cloudfun.svg"
          />
          <span class="text-white text-lg ml-3">
            {{ adminTitle }}
          </span>
        </router-link>
        <!-- END: Logo -->
        <!-- BEGIN: Breadcrumb -->
        <div class="-intro-x breadcrumb breadcrumb--light mr-auto">
          <div class="breadcrumb" v-for="(node, index) in $breadcrumb" :key="`breadcrumb-${index}`">
            <router-link v-if="$breadcrumb.length !== index + 1" :to="node.to">{{ node.title }}</router-link>
            <span v-else class="breadcrumb--active">{{ node.title }}</span>
            <ChevronRightIcon v-if="$breadcrumb.length !== index + 1" class="breadcrumb__icon" />
          </div>
        </div>
        <!-- END: Breadcrumb -->
        <!-- BEGIN: Search -->
        <div class="intro-x relative mr-3 sm:mr-6">
          <div class="search hidden sm:block">
            <input
              type="text"
              class="search__input input dark:bg-dark-1 placeholder-theme-13"
              placeholder="Search..."
              @focus="showSearchDropdown"
              @blur="hideSearchDropdown"
            />
            <SearchIcon class="search__icon dark:text-gray-300" />
          </div>
          <a class="notification notification--light sm:hidden" href="">
            <SearchIcon class="notification__icon dark:text-gray-300" />
          </a>
          <div class="search-result" :class="{ show: searchDropdown }">
            <div class="search-result__content">
              <div class="search-result__content__title">Pages</div>
              <div class="mb-5">
                <a href class="flex items-center">
                  <div
                    class="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"
                  >
                    <InboxIcon class="w-4 h-4" />
                  </div>
                  <div class="ml-3">Mail Settings</div>
                </a>
                <a href class="flex items-center mt-2">
                  <div
                    class="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"
                  >
                    <UsersIcon class="w-4 h-4" />
                  </div>
                  <div class="ml-3">Users & Permissions</div>
                </a>
                <a href class="flex items-center mt-2">
                  <div
                    class="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"
                  >
                    <CreditCardIcon class="w-4 h-4" />
                  </div>
                  <div class="ml-3">Transactions Report</div>
                </a>
              </div>
              <div class="search-result__content__title">Users</div>
              <div class="mb-5">
                <a
                  v-for="(faker, fakerKey) in $_.take($f(), 4)"
                  :key="fakerKey"
                  href
                  class="flex items-center mt-2"
                >
                  <div class="w-8 h-8 image-fit">
                    <img
                      alt="Midone Tailwind HTML Admin Template"
                      class="rounded-full"
                      :src="require(`@/assets/images/${faker.photos[0]}`)"
                    />
                  </div>
                  <div class="ml-3">{{ faker.users[0].name }}</div>
                  <div
                    class="ml-auto w-48 truncate text-gray-600 text-xs text-right"
                  >
                    {{ faker.users[0].email }}
                  </div>
                </a>
              </div>
              <div class="search-result__content__title">Products</div>
              <a
                v-for="(faker, fakerKey) in $_.take($f(), 4)"
                :key="fakerKey"
                href
                class="flex items-center mt-2"
              >
                <div class="w-8 h-8 image-fit">
                  <img
                    alt="Midone Tailwind HTML Admin Template"
                    class="rounded-full"
                    :src="require(`@/assets/images/${faker.images[0]}`)"
                  />
                </div>
                <div class="ml-3">{{ faker.products[0].name }}</div>
                <div
                  class="ml-auto w-48 truncate text-gray-600 text-xs text-right"
                >
                  {{ faker.products[0].category }}
                </div>
              </a>
            </div>
          </div>
        </div>
        <!-- END: Search -->
        <!-- BEGIN: Notifications -->
        <div class="intro-x dropdown relative mr-4 sm:mr-6">
          <div
            class="dropdown-toggle notification notification--light notification--bullet cursor-pointer"
          >
            <BellIcon class="notification__icon dark:text-gray-300" />
          </div>
          <div class="notification-content pt-2 dropdown-box">
            <div
              class="notification-content__box dropdown-box__content box dark:bg-dark-6"
            >
              <div class="notification-content__title">Notifications</div>
              <div
                v-for="(faker, fakerKey) in $_.take($f(), 5)"
                :key="fakerKey"
                class="cursor-pointer relative flex items-center"
                :class="{ 'mt-5': fakerKey }"
              >
                <div class="w-12 h-12 flex-none image-fit mr-1">
                  <img
                    alt="Midone Tailwind HTML Admin Template"
                    class="rounded-full"
                    :src="require(`@/assets/images/${faker.photos[0]}`)"
                  />
                  <div
                    class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
                  ></div>
                </div>
                <div class="ml-2 overflow-hidden">
                  <div class="flex items-center">
                    <a href="javascript:;" class="font-medium truncate mr-5">
                      {{ faker.users[0].name }}
                    </a>
                    <div
                      class="text-xs text-gray-500 ml-auto whitespace-nowrap"
                    >
                      {{ faker.times[0] }}
                    </div>
                  </div>
                  <div class="w-full truncate text-gray-600 mt-0.5">
                    {{ faker.news[0].shortContent }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Notifications -->
        <!-- BEGIN: Account Menu -->
        <div class="intro-x dropdown w-8 h-8 relative">
          <div
            class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110"
          >
            <img
              alt="Midone Tailwind HTML Admin Template"
              :src="$user.PhotoUri"
            />
          </div>
          <div class="dropdown-box w-56">
            <div
              class="dropdown-box__content box bg-theme-38 dark:bg-dark-6 text-white"
            >
              <div class="p-4 border-b border-theme-40 dark:border-dark-3">
                <div class="text-xs text-theme-41 mt-0.5 dark:text-gray-600">
                  Signed in as:
                </div>
                <div class="font-medium">{{ $user.Name }}<span v-if="!isGuest">({{$user.Account}})</span></div>
              </div>
              <div class="p-2" v-show="!isGuest">
                <a
                  href
                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                >
                  <LockIcon class="w-4 h-4 mr-2" /> 變更密碼
                </a>
                <a
                  href="javascript: void(0)"
                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                  @click="logout()"
                >
                  <ToggleLeftIcon class="w-4 h-4 mr-2" /> 登出
                </a>
              </div>
              <div class="p-2 border-t border-theme-40 dark:border-dark-3">
                <a 
                  v-if="isGuest" 
                  href="javascript: void(0)"
                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                  @click="logout()"
                >
                  <ToggleRightIcon class="w-4 h-4 mr-2" /> 登入
                </a>
                <a
                  href
                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                >
                  <HelpCircleIcon class="w-4 h-4 mr-2" /> 支援服務
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Account Menu -->
      </div>
    </div>
    <!-- END: Top Bar -->
    <!-- BEGIN: Top Menu -->
    <nav class="top-nav">
      <ul>
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu" :key="menuKey">
          <li v-if="menu !== 'devider'">
            <a
              href="javascript:;"
              class="top-menu"
              :class="{
                'top-menu--active': menu.active,
              }"
              @click="linkTo(menu, router)"
            >
              <div class="top-menu__icon">
                <component :is="menu.icon" />
              </div>
              <div class="top-menu__title">
                {{ menu.title }}
                <ChevronDownIcon
                  v-if="menu.subNodes"
                  class="top-menu__sub-icon"
                />
              </div>
            </a>
            <!-- BEGIN: Second Child -->
            <ul v-if="menu.subNodes">
              <li
                v-for="(subMenu, subMenuKey) in menu.subNodes"
                :key="subMenuKey"
              >
                <a
                  href="javascript:;"
                  class="top-menu"
                  @click="linkTo(subMenu, router)"
                >
                  <div class="top-menu__icon">
                    <component v-if="subMenu.icon" :is="subMenu.icon" />
                    <ActivityIcon v-else />
                  </div>
                  <div class="top-menu__title">
                    {{ subMenu.title }}
                    <ChevronDownIcon
                      v-if="subMenu.subNodes"
                      class="top-menu__sub-icon"
                    />
                  </div>
                </a>
                <!-- BEGIN: Third Child -->
                <ul v-if="subMenu.subNodes">
                  <li
                    v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subNodes"
                    :key="lastSubMenuKey"
                  >
                    <a
                      href="javascript:;"
                      class="top-menu"
                      @click="linkTo(lastSubMenu, router)"
                    >
                      <div class="top-menu__icon">
                        <component :is="'zap-icon'" />
                      </div>
                      <div class="top-menu__title">
                        {{ lastSubMenu.title }}
                      </div>
                    </a>
                  </li>
                </ul>
                <!-- END: Third Child -->
              </li>
            </ul>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </nav>
    <!-- END: Top Menu -->
    <!-- BEGIN: Content -->
    <div class="content">
      <router-view />
    </div>
    <!-- END: Content -->
  </div>
  <footer 
    class="flex justify-center text-white dark:text-white text-xs sm:text-sm mt-4"
  >
    Copyright&nbsp;&copy;2021&ensp;
    <a href="//www.cloudfun.com.tw" target="_blank" rel="noopener noreferrer">CloudFun</a>&nbsp;Co.,Ltd.&ensp;
    All&nbsp;Rights&nbsp;Reserved&ensp;
    版權所有，盜用必究
  </footer>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import {
  searchDropdown,
  showSearchDropdown,
  hideSearchDropdown,
} from "./index";
import { linkTo } from "@/layouts/side-menu";
import { mapGetters } from "vuex";
import context from "@cloudfun/core";

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    // DarkModeSwitcher,
  },
  setup() {
    const application = context.root;
    const policy = application?.policy;
    const route = useRoute();
    const router = useRouter();
    const formattedMenu = ref([]);
    const topMenu = computed(() => policy?.makeMenu('/top-menu') || []);

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(topMenu.value);
      }
    );

    const adminTitle = computed(() => application?.model.state.configuration.value.AdminTitle);

    onMounted(async () => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("app");
      formattedMenu.value = $h.toRaw(topMenu.value);
      application?.model.dispatch('configuration/read');
    });

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      formattedMenu,
      router,
      linkTo,
      isGuest: !application?.user.Id,
      adminTitle,
    };
  },
  computed: {
    ...mapGetters(['user']),
    homePath() {
      const path = this.$route.path;
      if (path.startsWith('/simple-menu')) return '/simple-menu';
      if (path.startsWith('/top-menu')) return '/top-menu';
      return '/';
    }
  },
  methods: {
    logout() {
      if (!this.$user.Id) {
        this.$router.push('/login')
        return;
      }
      this.$model.dispatch('logout').then(
        () => this.$router.push('/login') 
      );
    }
  },
  watch: {
    user (current) {
      this.isGuest = !current.Id;
    }
  }
});
</script>
