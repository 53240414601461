<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">訂單</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button v-show="!isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid 
          ref="grid" 
          v-bind="gridOptions" 
          @addNewRow="(row, callback) => { initData(row); callback(); }"
          @edit="onGridEdit"
          @modalResize="onGridModalResize"
          @removeSelectedRows="onGridRemoveSelectedRows" 
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form :title-width="100" title-align="right" v-bind="formOptions" :data="row" @reset="reset(defaultData, data)" @submit="submit">
              <template #order-list>
                <div class="p-2 border">
                  <div class="m-2">訂單內容（輕金屬協會會員免收郵件處理費70元）</div>
                  <button type="button" class="button rounded-lg bg-theme-1 mb-2 text-white" @click="AddOrderItem">新增項目</button>
                  <div class="table w-full p-2">
                    <!-- {{ data.OrderItems }} {{data.OrderItems.length > 0}} -->
                    <table class="w-full border">
                        <thead>
                            <tr class="bg-gray-50 border-b">
                                <th class="p-2 border-r text-sm font-thin">
                                    <div class="flex items-center justify-center">名稱</div>
                                </th>
                                <th class="p-2 border-r text-sm font-thin">
                                    <div class="flex items-center justify-center">價錢</div>
                                </th>
                                <th class="p-2 border-r text-sm font-thin">
                                    <div class="flex items-center justify-center">數量</div>
                                </th>
                                <th class="p-2 border-r text-sm font-thin">
                                    <div class="flex items-center justify-center">總價</div>
                                </th>
                                <th class="p-2 border-r text-sm font-thin">
                                    <div class="flex items-center justify-center">功能</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="data.OrderItems.length > 0">
                            <tr class="text-center border-b text-sm" v-for="(item, index) in data.OrderItems" :key="index">
                                <td class="p-2 border-r">{{ item.Book.Name }}</td>
                                <!-- <td class="p-2 border-r">{{ row.Enabled ? item.Book.MemberPrice : item.Book.Price }}</td> -->
                                <td class="p-2 border-r">{{ item.Price }}</td>
                                <td class="p-2 border-r">{{ item.Total }}</td>
                                <td class="p-2 border-r">{{ item.Amount }}</td>
                                <!-- <td class="p-2 border-r">{{ row.Enabled ? item.Book.MemberPrice * item.Total : item.Book.Price * item.Total }}</td> -->
                                <td>
                                    <button type="button" class="bg-red-500 p-2 text-white rounded-lg hover:shadow-lg text-sm" @click="deleteItem(item.Id, row)">刪除</button>
                                </td>
                            </tr>
                            <tr class="text-center border-t-4 text-sm">
                                <td class="p-2 border-r">運費</td>
                                <td class="p-2 border-r"></td>
                                <td class="p-2 border-r"></td>
                                <td class="p-2 border-r">{{ data.Enabled ? 0 : 70 }}</td>
                                <td></td>
                            </tr>
                            <tr class="text-center border-t text-sm">
                                <td class="p-2 border-r">總金額</td>
                                <td class="p-2 border-r"></td>
                                <td class="p-2 border-r"></td>
                                <td class="p-2 border-r">{{ data.Amount }}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </vxe-form>

            <vxe-modal ref="modal" v-model="form.showEdit" :title="'新增訂單項目'"
            width="600" show-zoom="true" lock-scroll="false" height="180" min-width="600" min-height="180" resize destroy-on-close >
              <template #default>
                <vxe-form :data="form.formData" :items="form.formItems" :rules="form.formRules" @submit="submitOrderItem(row)">
                </vxe-form>
              </template>
            </vxe-modal>
          </template>
        </grid>
      </div>
    </div>

    
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
  .swiper-container {
    padding-top: 5px;
    padding-bottom: 30px;
  }
</style>
<style>
  textarea {
    min-height: 140px;  
  }
  .vxe-select-option{
    max-width: 100% !important;
  }
</style>

<script>
/* eslint-disable */
import { onMounted, reactive, readonly } from "vue";
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";
import { VxeFormPropTypes } from "vxe-table";
import { faLaptopHouse } from "@fortawesome/free-solid-svg-icons";

export default defineComponent({
  components:{
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;

    const grid = ref({});
    var data = reactive({});

    var printColumns = [
      { field: 'Name' }, 
      { field: 'Author' },
      { field: 'Price' },
      { field: 'MemberPrice' },
      { field: 'PublishedString' },
      { field: 'Ordinal' },
    ]

    const defaultData = ref({
      init: [
        {name: 'Enabled', content: false},
        {name: 'OrderStatus', content: 0},
        //{name: 'Address', content: { City: '台北市', District: '中正區' }}
      ],
      reset:[]
    });

    // const changeCity = () => {
    //   form.DistrictsList = districtList(data.Address.City); 
    //   var districtOption = formOptions.items[7];
    //   if (districtOption.itemRender) {
    //     districtOption.itemRender.options = form.DistrictsList;
    //     data.Address.District = form.DistrictsList[0]['value'];
    //   }
    // }

    const ChangeByEnabled = (row) =>{
      var member = formOptions.items[2];
      if (member) member.itemRender.props.disabled = row.data.Enabled ? false : true;
      row.data.Freight = row.data.Enabled ? 0 : 70;
    };

    const gridOptions = {
      title: '訂單',
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      sortConfig: { defaultSort: { field: 'CreatedTime', order: 'desc' } },
      printConfig: { sheetName: '訂單清單', columns: printColumns, modes: [ 'current', 'selected', 'all' ] },
      exportConfig: { filename: '訂單清單', type: 'csv', types: [ 'html', 'csv' ], mode: 'all', modes: [ 'current', 'selected', 'all' ], columns: printColumns },
      columns: [
        {
          field: 'Name', title: '姓名', showHeaderOverflow: true, showOverflow: true, sortable: true,
          editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        },        
        {
          field: 'OrderStatus', title: '狀態', sortable: true, width: 200, formatter: ({ cellValue }) => { return form.OrderStatus ? form.OrderStatus?.find(e => e.value === cellValue)?.label : ""; }
        },
        {
          field: 'Phone', title: '電話', showHeaderOverflow: true, showOverflow: true, sortable: true,
          editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        },
        {
          field: 'Email', title: '收據抬頭', sortable: true,
        },
        {
          field: 'Amount', title: '總金額', sortable: true,
        },
      ],
      promises: {
        query: model ? (params) => { if(params.sortings.length === 0) params.sortings.push({ column: 'CreatedTime', order: 1 }); return model.dispatch('order/query', params) }: undefined,
        queryAll: model ? () => model.dispatch('order/query', { keyword: grid.value.keyword, type: 2 }) : undefined,
        save: model ? (params) => model.dispatch('order/save', params) : undefined
      },
      modalConfig: { height: 350 }
    };
    const formOptions = {
      items: [
        { field: 'Name', title: '姓名', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } } },
        { field: 'Enabled', title: '是否為會員', span: 12, itemRender: { name: '$radio', options: [ { label: '否', value: false }, { label: '是', value: true }], props: { disabled: false }, events: { change: ChangeByEnabled } } },
        { field: 'MemberId', title: '會員', span: 12, itemRender: { name: '$select', options:[], props: { clearable: true, disabled: false } }, },
        { field: 'OrderStatus', title: '狀態', span: 12, itemRender: { name: '$select', options:[] }, },
        { field: 'Phone', title: '電話', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } } },
        { field: 'Email', title: '收據抬頭', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } } },
        // { field: 'Address.City', title: '縣市', span: 6, itemRender: { name: '$select', options:[], events: { change: changeCity } } },
        // { field: 'Address.District', title: '行政區', span: 6, itemRender: { name: '$select', options:[] } },
        { field: 'Amount', title: '總金額', span: 12, itemRender: { name: '$input', props: { placeholder: '', readonly: true, disabled: true } } },
        { field: 'Freight', title: '運費', span: 12, itemRender: { name: '$input', props: { placeholder: '', readonly: true, disabled: true } } },
        { field: 'Address.Line', title: '詳細地址', span: 12, itemRender: { name: '$input', props: { placeholder: '詳細地址', clearable: true } } },
        { span: 24, slots: { default: 'order-list' }, visible: false },
        {
          span: 24, align: "right",
          itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確定', status: 'primary' } }, { props: { type: 'reset', content: '重置' } }] }
        }
      ],
      rules: { 
        Name: [{ type: 'string', max: 128, required: true, message: '請輸入姓名' }],
        Phone: [{ type: 'string', required: true, message: '請輸入電話', pattern: new RegExp("^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$") }],
        'Address.Line': [{ type: 'string', required: true, message: '請輸入詳細地址' }],
        Email: [{ type: 'string', message: '請輸入收據抬頭', required: true }],
        Enabled: [{ required: true }],
      }
    };

    const initData = (row) => {
      row.Published = false;
      row.Enabled = false;
      row.Ordinal = 0;      
      row.Amount = 0;
      row.Freight = 70;
      row.MemberPrice = 0;
      row.OrderStatus = 0;
      //row.Address = { City: '台北市', District: '中正區', Line: '' }
      // form.DistrictsList = districtList('台北市');
      // var districtOption = formOptions.items[7];
      // if (districtOption.itemRender) {
      //   districtOption.itemRender.options = form.DistrictsList; 
      //   row.Address = {
      //     City: form.CityList[0]['value'],
      //     District: form.DistrictsList[0]['value'],
      //   }
      // }
      Object.assign(data, row);
      var item = formOptions.items[9]; 
      if (item) item.visible = false;
      var member = formOptions.items[1];
      if (member) member.itemRender.props.disabled = false;
      var memberList = formOptions.items[2];
      if (memberList) memberList.itemRender.props.disabled = true;
    }

    const getMember = () => {
        return new Promise((resolve, reject) => {
          model.dispatch('member/query', { keyword: null, skipRows: null, takeRows: null, simple: true }).then(
            response => resolve(response), 
            reason =>  reject(reason)
          );
      });
    };

    const getBook = () => {
        return new Promise((resolve, reject) => {
          model.dispatch('book/query', { keyword: null, skipRows: null, takeRows: null, simple: true }).then(
            response => resolve(response), 
            reason =>  reject(reason)
          );
      });
    };

    const form = reactive({
      CityList: [],
      DistrictsList: [],
      OrderStatus: [],
      showEdit: false,
      formData: {
        Id: 0,
        BookId: null,
        Total: 1,
        OrderId: 0
      },
      formRules: {
        BookId: [
          { required: true, message: '請輸入書籍' },
        ],
        Total: [
          { required: true, type: 'number', message: '請輸入數量(最少為1)', min: 1 },
        ],
      },
      formItems: [
        { field: 'BookId', title: '書籍', span: 12, itemRender: { name: '$select', options:[], clearable: true }, },
        {
          field: 'Total', title: '數量', span: 12, 
          itemRender: { name: '$input', props: { type:'number', placeholder: '數量', clearable: true } },
        },
        { align: 'right', span: 24, titleAlign: 'left', itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確認', status: 'primary' } } , { props: { type: 'reset', content: '重置' } }] } }
      ]
    })

    const submitOrderItem = (row) => {
      form.formData.OrderId = data.Id;
      model?.dispatch(`orderItem/insert`, form.formData).then(
        paylod => {
          ReloadItem(row)
          CloudFun.send('info', { subject: '新增成功' , content: '訂單項目新增完成' })
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
      ).finally(() => { 
        form.showEdit = false;
      });
    };

    const ReloadItem = (row) => {
      model?.dispatch('order/find', data.Id).then(
        paylod => {
          //console.log(row)
          if(paylod.OrderItems && paylod.OrderItems.length > 0) { 
            //data.OrderItems = paylod.OrderItems;
            Object.assign(row, paylod);
            Object.assign(data, paylod);
            defaultData.value.reset[0] = { name: 'OrderItems', content: data.OrderItems};
          } else {
            //data.OrderItems = [];
            Object.assign(row, paylod);
            Object.assign(data, paylod);
            defaultData.value.reset[0] = { name: 'OrderItems', content: []};
            //console.log('0',defaultData.value.reset)
          }
        },
        failure => CloudFun.send('error', { subject: '附件讀取失敗', content: failure.message })
      ).finally(() => {
        grid.value.instance.commitProxy('query');
      });
    }

    onMounted(() => {
      // form.CityList = cityList();
      // var citytOption = formOptions.items[6];
      // if (citytOption.itemRender) citytOption.itemRender.options = form.CityList;
      getMember().then((data) =>{  
        var item = formOptions.items[2]; 
        if (item.itemRender && data.length > 0) {
          item.itemRender.options = data;
        }
      });
      getBook().then((data) =>{  
        var item = form.formItems[0]; 
        if (item.itemRender && data.length > 0) {
          item.itemRender.options = data;
        }
      });
      const orderStatusEnum = Object.values(model.enums.OrderStatus).map(e => { return { label: e.Name, value: e.Value } })
      var item = formOptions.items[3];
      if (item.itemRender && orderStatusEnum.length > 0) {            
        form.OrderStatus = orderStatusEnum; 
        item.itemRender.options = orderStatusEnum;                  
      }
    })

    return {
      form,
      initData,
      defaultData,
      data,
      grid,
      gridOptions,
      formOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      submitOrderItem,
      ReloadItem
    };
  },
  methods: {
    Amount(enabled) {
      var priceList = this.data.OrderItems.map(function(item) {
        return enabled ? item.Book.MemberPrice * item.Total : item.Book.Price * item.Total;
      });
      var sum = 0;
      priceList.forEach((e) => sum += e);
      return sum;
    },
    onGridModalResize(size) {
      this.sliderWidth = `${size.width-100}px`;
    },
    async onGridEdit(row, callback) {
      const entity = row.Id ? await this.$model.dispatch('order/find', row.Id) : undefined;
      if (entity) {
        Object.assign(row, entity);
        Object.assign(this.data, row);
        this.defaultData.reset[0] = { name: 'OrderItems', content: this.data.OrderItems};
        //if(row.Address && row.Address.City) this.defaultDistrict(row.Address.District)
        this.grid.lastModalConfig.height = 550
        var item = this.formOptions.items[9];
        if (item) item.visible = true;
        var member = this.formOptions.items[1];
        if (member) member.itemRender.props.disabled = true;
      } else this.grid.lastModalConfig.height = 400     
      callback();
    },
    onGridRemoveSelectedRows(rows, callback) {
      cash('#batch-dropdown').dropdown('hide');
      callback();
    },
    // defaultDistrict(district) {
    //   this.form.DistrictsList = districtList(this.data.Address.City); 
    //   var districtOption = this.formOptions.items[6];      
    //   if (districtOption.itemRender) {
    //     districtOption.itemRender.options = this.form.DistrictsList;
    //     this.data.Address.District = district;
    //   }
    // },
    AddOrderItem() {
      this.form.showEdit = true;
      this.form.formData.Id = 0;
      this.form.formData.BookId = null;
      this.form.formData.Total = 1;
      this.form.formData.OrderId = 0;
    },
    deleteItem(id, row) {
      this.$model.dispatch(`orderItem/delete`, id).then(
        () => {
          CloudFun.send('info', { subject: '刪除成功！', content: '訂單項目刪除成功' })
          this.ReloadItem(row)
        },
        failure => CloudFun.send('error', { subject: '訂單項目刪除失敗！', content: failure})
      ).finally(() => {
      });
    },
  },
});
</script>
