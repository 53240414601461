/* eslint-disable */

// 縣市行政區
const list = require("@/assets/json/city.json");

export function cityList() {    
    return list.map((e: any)=> { return { value: e.name, label:e.name } } );
}

export function districtList(city: any) {
    city = city.trim();
    var result = list.filter((e: any)=> e.name == city);
    return (result[0]).districts.map((e: any)=> { return { value: e.name, label:e.name, zip: e.zip } } );
}

export function GetZip(city: any, district: any) {
    city = city.trim();
    district = district.trim();
    var result = list.filter((e: any)=> e.name == city);   
    return result[0].districts.filter((e: any)=> e.name == district).map((e: any)=> e.zip)[0];
}