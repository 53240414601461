<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 h-8">
      <h2 class="text-lg font-medium mr-auto">會員管理</h2>      
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!isEditing && isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onPrint('selected')"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExport('csv', 'selected')"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span> -->
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExport('csv', 'selected')"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button v-show="!isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增會員
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form class="xl:flex sm:mr-auto" @submit.prevent="onFilter">
          <!-- <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
            <select class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model="selectdCity" @change="grid.commitProxy('query')">
              <option value="">請選擇縣市</option>
              <option v-for="(option, index) in form.CityList" :key="index" :value="option.value">{{ option.label }}</option>
            </select>
          </div> -->
          <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
            <select class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model="selectdType" @change="grid.commitProxy('reload')">
              <option value="">請選擇會員類型</option>
              <option value="0">個人會員</option>
              <option value="1">團體會員</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model="keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.commitProxy('reload')"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.commitProxy('reload')"
            >
              搜尋
            </button>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="onPrint('curret')"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExport('xlsx')"
                >
                  <FontAwesome icon="file-excel" class="w-4 h-4 mr-2" /> Excel
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExport('html')"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <vxe-grid 
          id="grid"
          ref="grid" 
          class="mt-5 table-report" 
          v-bind="gridOptions"
        >
          <template #operate="{ row }">
            <slot name="operate" :row="row">              
              <div class="flex" v-if="!isEditing">
                <button class="button rounded-full mr-1 mb-2 bg-theme-1 text-white" title="編輯" @click="edit(row)">
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button>
                <button class="button rounded-full mr-1 mb-2 bg-theme-6 text-white" title="取消" @click="remove(row)">
                  <FontAwesome icon="trash" class="w-4 h-4" />
                </button>
              </div>
            </slot>
          </template>
          <template #name_filter="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox" 
                class="input input--switch border mt-4 ml-2" 
                :checked="option.checked"
                @input="changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </vxe-grid>
      </div>
    </div>
<!--  -->
    <vxe-modal v-model="form.showEdit" :title="form.model == 'edit' ? '編輯會員' : '新增會員'"
    show-zoom="true" fullscreen remember width="1330" height="570" min-width="800" min-height="300" lock-scroll="false" destroy-on-close>
      <template #default>
        <div v-if="form.model == 'edit' && form.formData.Type == 1" class="w-full text-center grid grid-cols-2 border">
          <div class="mt-2 mb-2">
            <div class="mb-2">產品圖片1</div>
            <file-upload
            name="file1"
            ref="uploader1"
            extensions="gif,jpg,jpeg,png,webp"
            accept="image/png,image/gif,image/jpeg,image/webp"
            :drop="true"
            v-model="file1"
            @input-filter="inputFilter1"
            @input-file="inputFile"
            @change="UploadPhotoFiles(1)"
            >
            <img
              class="border p-1 rounded"
              style="max-width:500px; max-height:320px;"
              :src="form.formData.ProductPhoto1.Uri ? form.formData.ProductPhoto1.Uri : file1.length ? file1[0].url : 'https://via.placeholder.com/500x320.png?text=Click%20or%20Drop%20file%20here'"
            />
          </file-upload>
            <div v-if="form.formData.ProductPhoto1.Uri || file1.length > 0">
              <button type="button" class="button rounded-full mr-1 mb-2 bg-theme-1 text-white" @click="deletePicture(1)">刪除圖片</button>
            </div>
          </div>
          <div class="mt-2 mb-2">
            <div class="mb-2">產品圖片2</div>
            <file-upload
            name="file2"
            ref="uploader2"
            extensions="gif,jpg,jpeg,png,webp"
            accept="image/png,image/gif,image/jpeg,image/webp"
            :drop="true"
            v-model="file2"
            @input-filter="inputFilter2"
            @input-file="inputFile"
            @change="UploadPhotoFiles(2)"
            >
            <img
              class="border p-1 rounded"
              style="max-width:500px; max-height:320px;"
              :src="form.formData.ProductPhoto2.Uri ? form.formData.ProductPhoto2.Uri : file2.length ? file2[0].url : 'https://via.placeholder.com/500x320.png?text=Click%20or%20Drop%20file%20here'"
            />
          </file-upload>
            <div v-if="form.formData.ProductPhoto2.Uri || file2.length > 0">
              <button type="button" class="button rounded-full mr-1 mb-2 bg-theme-1 text-white" @click="deletePicture(2)">刪除圖片</button>
            </div>
          </div> 
        </div>
        <vxe-form :data="form.formData" :items="form.formItems" :rules="form.formRules" title-align="right" title-width="100" @submit="submitSave" @reset="edit(form.formData)"></vxe-form>
      </template>
    </vxe-modal>

    <!-- END: HTML Table Data -->
  </div>
</template>
<style>
  .vxe-checkbox {
    margin: 5px !important;
  }
</style>
<script lang="ts">
/* eslint-disable */

import { onMounted } from "vue";
import CloudFun, { defineComponent, ref, reactive, computed, Sorting, SortOrder, Condition, Operator, LogicalConnective, Model } from "@cloudfun/core";
import { method } from "node_modules/@types/lodash";
import { VxeColumnPropTypes, VxeGridInstance, VxeGridProps, VxeFormPropTypes, Column } from "vxe-table";
import { cityList, districtList, GetZip } from '@/libs/city';
import FileUpload from 'vue-upload-component';
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import { NumberFormat } from "vue-i18n";

export default defineComponent({
  components:{    
    FileUpload,    
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);
    const loading = ref(false);
    const editingRow = ref(null);
    const keyword = ref(""); 
    const selectdCity = ref("");
    const uploader1 = ref({});
    const uploader2 = ref({});
    const file1 = ref([]);
    const file2 = ref([]);
    const selectdType = ref("");
    const customFilters = ref<Condition[]>([]);
    const isRowSelected = computed(() => (grid.value.getRadioRecord && grid.value.getRadioRecord()) || (grid.value.getCheckboxRecords && grid.value.getCheckboxRecords().length > 0));
    const isEditing = computed(() => editingRow.value != null && grid.value.isActiveByRow(editingRow.value));
    var printColumns = [
      { field: 'Nickname' }, 
      { field: 'Type' },
      { field: 'Organization' },
      { field: 'Principal' },
      { field: 'Account' },
      { field: 'Status' },      
      { field: 'Email' }, 
      { field: 'Person.Phone' },
      { field: 'Person.Fax' },
      { field: 'Person.Address.Line' },
      { field: 'Recommend'},
      { field: 'Person.MobilePhone'},
      { field: 'JobTitle'},
      { field: 'JobType'},
      { field: 'Education'},
      { field: 'School'},
      { field: 'Person.BirthDate'},
      { field: 'OrganizationEnglish'},
      { field: 'Tax'},
      { field: 'OrgUrl'},
      { field: 'BuildTime'},
      { field: 'Capital'},
      { field: 'MemberTotal'},
      { field: 'Representative1'},
      { field: 'Phone1'},
      { field: 'Email1'},
      { field: 'JobTitle1'},
      { field: 'Representative2'},
      { field: 'Phone2'},
      { field: 'Email2'},
      { field: 'JobTitle2'},
      { field: 'Representative3'},
      { field: 'Phone3'},
      { field: 'Email3'},
      { field: 'JobTitle3'},
      { field: 'Contact'},
      { field: 'ContactEmail'},
      { field: 'ContactJobTitle'},
      { field: 'ContactPhone'},
      { field: 'ContactFax'},
      { field: 'ContactAddress'},
      { field: 'MainItem'},
      { field: 'Technology'},
      { field: 'Overseas1'},
      { field: 'Overseas2'},
      { field: 'Overseas3'},
      { field: 'Overseas4'},
      { field: 'Classifications'},
      { field: 'Certifications'},
      { field: 'Other'},
      { field: 'CertificationOther'},
      { field: 'Introduction'}, 
    ]
    // 'Person.Address.Line', 'Organization', <<公司改為共通
    const groupArray = [ 'Other','CertificationOther','Overseas1','Overseas2','Overseas3','Overseas4','Phone1','Phone2','Phone3','ContactAddress','ContactFax','ContactPhone','Certifications', 'Classifications','ContactJobTitle', 'Contact', 'ContactEmail', 'Introduction','OrganizationEnglish','Tax', 'OrgUrl', 'Principal' ,'BuildTime','Capital','MemberTotal','Representative1','Email1','JobTitle1','Representative2','Email2','JobTitle2','Representative3','Email3','JobTitle3','MainItem','Technology',]
    const personalArray = [ 'JobTitle', 'JobType', 'Unit', 'Education', 'School', 'Person.BirthDate', 'Person.Sex', 'Person.MobilePhone']

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: true,
      loading: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: { remote: true, multiple: true },
      //editConfig: { trigger: 'manual', mode: 'row', showIcon: false, autoClear: false },
      pagerConfig: { currentPage: 1, pageSize: 10, pageSizes: [5, 10, 20], layouts: [ 'PrevJump', 'PrevPage', 'Jump', 'PageCount', 'NextPage', 'NextJump', 'Sizes', 'Total' ] },
      printConfig: { sheetName: '會員清單', columns: printColumns, modes: [ 'current', 'selected', 'all' ] },
      exportConfig: { filename: '會員清單', type: 'xlsx', types: [ 'xlsx','html', 'csv' ], mode: 'all', modes: [ 'current', 'selected', 'all' ], columns: printColumns,
        columnFilterMethod: (columns) => { return columns.column.visible; }
      },
      columns: [
        { type: "checkbox", width: 35, fixed: 'left', resizable: false },
        { 
          field: "Nickname", title: "姓名", showHeaderOverflow: true, showOverflow: true, sortable: true,
          // filters: [{ checked: false, label: '包含' }],
          // slots: { filter: 'name_filter' }
        },
        { field: "Type", title: "會員類型", sortable: true, showHeaderOverflow: true, showOverflow: true, resizable: true, formatter: ({ cellValue }) => { return Object.values(model!.enums.MemberType).find(e => e.Value === cellValue).Name; } },    
        { field: "Organization", title: "公司名稱", sortable: true, showHeaderOverflow: true, showOverflow: true, resizable: true },
        { field: "Principal", title: "負責人", sortable: true, showHeaderOverflow: true, showOverflow: true, resizable: true },        
        { field: "Account", title: "帳號", sortable: true, showHeaderOverflow: true, showOverflow: true, resizable: true },
        { field: "Status", title: "帳號狀態", sortable: true, showHeaderOverflow: true, showOverflow: true, resizable: true, formatter: ({ cellValue }) => { return Object.values(model!.enums.MemberStatus).find(e => e.Value === cellValue).Name; } },
        { field: "Email", title: "Email", sortable: true, showHeaderOverflow: true, showOverflow: true, resizable: true },
        { field: "Person.Phone", title: "聯絡電話", sortable: true, showHeaderOverflow: true, showOverflow: true, resizable: true },

        { field: "Person.Address.Line", title: "聯絡/公司地址", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Person.Fax", title: "傳真", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Recommend", title: "入會推薦人", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Person.MobilePhone", title: "手機", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "JobTitle", title: "單位/職稱", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "JobType", title: "專長", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Education", title: "學歷", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "School", title: "經歷", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Person.BirthDate", title: "出生年月日", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "OrganizationEnglish", title: "公司英文名稱", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Tax", title: "統一編號", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "OrgUrl", title: "網址", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "BuildTime", title: "成立日期", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Capital", title: "資本額(萬元)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "MemberTotal", title: "員工人數", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Representative1", title: "會員代表(1)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Phone1", title: "電話(1)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Email1", title: "Email(1)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "JobTitle1", title: "職稱(1)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Representative2", title: "會員代表(2)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Phone2", title: "電話(2)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Email2", title: "Email(2)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "JobTitle2", title: "職稱(2)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Representative3", title: "會員代表(3)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Phone3", title: "電話(3)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Email3", title: "Email(3)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "JobTitle3", title: "職稱(3)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Contact", title: "聯絡人", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "ContactEmail", title: "聯絡人Email", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "ContactJobTitle", title: "聯絡人職稱", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "ContactPhone", title: "聯絡人電話", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "ContactFax", title: "聯絡人傳真", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "ContactAddress", title: "聯絡人地址", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "MainItem", title: "主要營業項目", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Technology", title: "主要技術", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Overseas1", title: "海外事業(1)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Overseas2", title: "海外事業(2)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Overseas3", title: "海外事業(3)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Overseas4", title: "海外事業(4)", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Classifications", title: "營業性質", showHeaderOverflow: true, showOverflow: true, resizable: true, formatter: ({ cellValue }) => { return form.ClassificationList && cellValue ? form.ClassificationList?.filter(e => cellValue.indexOf(e['value']) != -1).map(e => e['label']).join() : ""; }, visible: false },
        { field: "Certifications", title: "國際認證", showHeaderOverflow: true, showOverflow: true, resizable: true, formatter: ({ cellValue }) => { return form.CertificationList && cellValue ? form.CertificationList?.filter(e => cellValue.indexOf(e['value']) != -1).map(e => e['label']).join() : ""; }, visible: false },
        { field: "Other", title: "營業性質-其它", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "CertificationOther", title: "國際認證-其它", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false },
        { field: "Introduction", title: "公司簡介", showHeaderOverflow: true, showOverflow: true, resizable: true, visible: false }, 

        { field: "operate", title: "操作", width: 100, fixed: 'right', align: 'center', resizable: false, slots: { default: 'operate' } },
      ],
      proxyConfig: {
        message: false,
        sort: true, 
        filter: true,
        props: { result: 'data', total: 'totalCount' }, 
        ajax: {
          query: (params) => {    
            //params.sorts.push({ column: Column.prototype, order: 'desc', sortBy: 'Number', property:'Number' });    
            
            const queryParams: { page: number, pageSize: number, keyword: string, city: string, type?: any, sortings?: Sorting[], condition: Condition, } = { 
              page: params.page.currentPage, 
              pageSize: params.page.pageSize, 
              keyword: keyword.value,
              city: selectdCity.value,
              type: selectdType.value, //
              sortings: params.sorts.filter(e => e.property).map(e => new Sorting(e.property, e.order === 'desc' ? SortOrder.Descending : SortOrder.Ascending)), // sorts
              condition: new Condition(customFilters.value) // custom filters
            };
            if(queryParams.sortings?.length === 0) queryParams.sortings?.push(new Sorting('CreatedTime', 1));
            // filters
            const filters = params.filters ? params.filters.filter(e => e.values.length) : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach(filter => { 
                const columnCondition = new Condition();
                filter.values.forEach(subFilter => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And) columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) => model?.dispatch('member/load', queryParams).then(
              payload => {
                console.log('promise',payload)
                resolve(payload)
              },
              reason => {               
                CloudFun.send('error', { subject: '讀取失敗', content: reason })
                reject(reason);
              }
            ));
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) => model?.dispatch('member/save', deleteParams).then(
              payload => resolve(payload),
              reason => {
                CloudFun.send('error', { subject: '刪除失敗', content: reason })
                reject(reason);
              }
            ));
          },
          save: (params) => {
            const saveParams = { insertRows: params.body.insertRecords, updateRows: params.body.updateRecords, deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) => model?.dispatch('member/save',  saveParams).then(
              payload => { 
                editingRow.value = null;
                resolve(payload)
              },
              reason => {
                CloudFun.send('error', { subject: '保存失敗', content: reason })
                reject(reason);
              }
            ));
          }
        }
      }
    } as VxeGridProps);

    //modal 執行 新增or修改
    const submitSave = () =>{
      var method = form.model == 'insert' ? 'insert' : 'update';

      //確認密碼
      if((form.formData.Password || form.formData.Password2) && form.formData.Password != form.formData.Password2) {
         CloudFun.send('error', { subject: '操作失敗！', content: '密碼不一致，請確認輸入！' })
        return;
      }

      gridOptions.loading = true;
      //form.formData.Person.Address.PostalCode = GetZip(form.formData.Person.Address.City, form.formData.Person.Address.District);
      
      model?.dispatch(`member/${method}`, form.formData).then(
        paylod => {
          //var sorting = new Sorting('CreatedTime', 1);          
          grid.value.commitProxy('query').finally(() => {  });
          CloudFun.send('info', { subject: method == 'insert' ? '新增成功' : '更新成功', content: method == 'insert' ? '會員新增完成' : '會員更新完成' })
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
      ).finally(() => { 
        form.showEdit = false; 
        gridOptions.loading = false;
      });
    }

    const changeCity = () => {      
      form.DistrictsList = districtList(form.formData.Person.Address.City); 
      var districtOption = form.formItems[9];      
      if (districtOption.itemRender) {
        districtOption.itemRender.options = form.DistrictsList;
        form.formData.Person.Address.District = form.DistrictsList[0]['value'];
        //console.log(GetZip(form.formData.Person.Address.City, form.formData.Person.Address.District))
      }
    }

    const VisibleByType = (data: any) =>{
      if(groupArray.find(e => e == data.property) && form.formData.Type == 1) return true;
      if(personalArray.find(e => e == data.property) && form.formData.Type == 0) return true;
      return false;
    };

    const ChangeByType = () => {
      if(form.formRules['Person.Address.Line'] && form.formRules['Organization']) {
        form.formRules['Organization'][0].required = form.formData.Type == 0 ? false : true
        form.formRules['Person.Address.Line'][0].required = form.formData.Type == 0 ? false : true
      }
      for(var i in groupArray) if(form.formRules[groupArray[i]] && groupArray[i] != 'Email2' && groupArray[i] != 'Email3'){ form.formRules[groupArray[i]][0].required = form.formData.Type == 1 ? true : false; }
      for(var j in personalArray) if(form.formRules[personalArray[j]]) form.formRules[personalArray[j]][0].required = form.formData.Type == 0 ? true : false;
    };


    const form = reactive({
      model: 'insert',   
      CityList: [],
      DistrictsList: [],
      CategoryList: [],
      StatusList: [],
      CertificationList: [],
      ClassificationList: [],
      TypeList: [],
      selectRow: true,
      showEdit: false,
      formData: {
        Id: "00000000-0000-0000-0000-000000000000",
        CreatedTimeString: '',
        Nickname: '',
        CreatedTime:'',
        Status: 0,
        StatusString: '',
        Email: '',        
        Account: '',        
        Password: '',
        Password2: '',
        Remark: '',   
        Classifications: [], 
        Certifications: [], 
        JobTitle: '',
        JobType: '',
        Unit: '',
        Education: '',
        School: '',
        Organization: '',
        OrganizationEnglish: '',
        Tax: '',
        OrgUrl: '',
        Principal: '',
        BuildTime: '',
        Capital: 0,
        MemberTotal: 0,
        Representative1: '',
        Email1: '',
        JobTitle1: '',
        Representative2: '',
        Email2: '',
        JobTitle2: '',
        Representative3: '',
        Email3: '',
        JobTitle3: '',
        Phone1: '',
        Phone2: '',
        Phone3: '',
        Contact: '',
        ContactEmail: '',
        ContactJobTitle: '',
        ContactAddress: '',
        ContactFax: '',
        ContactPhone: '',
        MainItem: '',
        Technology: '',
        Type: 0,
        Introduction: '',
        Recommend: '',
        Overseas1: '',
        Overseas2: '',
        Overseas3: '',
        Overseas4: '',
        CertificationOther: '',
        Other: '',
        Person: {
          Id: 0,
          Fax: '',
          Phone: '',
          MobilePhone: '',
          Sex: 0,
          BirthDate: '',
          Address:{
            Id: 0,          
            City: '',
            District: '',
            Line: '', 
            PostalCode: '',           
          }
        },
        ProductPhoto1: {
          Id: 0,
          Uri: '',
        },
        ProductPhoto2: {
          Id: 0,
          Uri: '',
        },
      },
      formRules: {
        Nickname: [
          { required: true, message: '請輸入姓名' },          
        ],
        Email: [
          { required: true, message: '請輸入Email' },          
        ],
        Account: [
          { required: true, message: '請輸入帳號' }
        ],
        Status: [
          { required: true, message: '請輸入狀態' }
        ],        
        Password: [
          { required: false, message: '請輸入密碼(至少八位英數字、特殊符號)', pattern: /^((?=.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!\u0022#$%&'()*+,./:;<=>?@[\]\^_`{|}~-]).*)/ }
        ],
        Password2: [
          { required: false, message: '請輸入確認密碼(至少八位英數字、特殊符號)', pattern: /^((?=.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!\u0022#$%&'()*+,./:;<=>?@[\]\^_`{|}~-]).*)/ }
        ],
        // 'Person.Address.City': [
        //   { required: true, message: '請輸入縣市' }
        // ],
        // 'Person.Address.District': [
        //   { required: true, message: '請輸入行政區' }
        // ],
        'Person.Address.Line': [
          { required: true, message: '請輸入詳細地址' }
        ],
        'Profile.Fax': [
          { message: '無效的傳真號碼', pattern: new RegExp("^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$") }
        ],
        'Person.Phone': [
          { message: '無效的電話號碼', pattern: new RegExp("^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$") }
        ],
        'Person.MobilePhone': [
          { message: '無效的手機號碼', pattern: new RegExp("^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$") }
        ],
        'Phone1': [
          { message: '無效的電話號碼', pattern: new RegExp("^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$") }
        ],
        Type: [
          { required: true, message: '請輸入會員類型' }
        ],
        JobTitle: [ //12-15 = 個人會員必填
          { required: true, message: '此欄位必填' }
        ],
        Unit: [
          { required: true, message: '此欄位必填' }
        ],
        'Person.BirthDate': [
          { required: true, message: '此欄位必填' }
        ],
        'Person.Sex': [
          { required: true, message: '此欄位必填' }
        ],
        Organization: [ //16-29 = 團體會員必填
          { required: true, message: '此欄位必填' }
        ],
        // Introduction: [
        //   { required: true, message: '此欄位必填' }
        // ],
        Tax: [
          { required: true, message: '此欄位必填' }
        ],
        OrgUrl: [
          { required: true, message: '此欄位必填', pattern: new RegExp("(https?:\\/\\/[\\w-\\.]+(:\\d+)?(\\/[~\\w\\/\\.]*)?(\\?\\S*)?(#\\S*)?)") }
        ],
        Principal: [
          { required: true, message: '此欄位必填' }
        ],
        BuildTime: [
          { required: true, message: '此欄位必填' }
        ],
        Capital: [
          { required: true, message: '此欄位必填' }
        ],
        MemberTotal: [
          { required: true, message: '此欄位必填' }
        ],
        Representative1: [
          { required: true, message: '此欄位必填' }
        ],
        Email1: [
          { required: true, message: '此欄位必填', pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }
        ],
        Email2: [
          { required: false, message: '請輸入正確Email', pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }
        ],
        Email3: [
          { required: false, message: '請輸入正確Email', pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }
        ],
        JobTitle1: [
          { required: true, message: '此欄位必填' }
        ],
        Contact: [
          { required: true, message: '此欄位必填' }
        ],
        ContactEmail: [
          { required: true, message: '此欄位必填', pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }
        ],
        ContactJobTitle: [
          { required: true, message: '此欄位必填' }
        ],
        ContactAddress: [
          { required: true, message: '此欄位必填' }
        ],
        ContactPhone: [
          { required: true, message: '此欄位必填' }
        ],
        MainItem: [
          { required: true, message: '此欄位必填' }
        ],
        Technology: [
          { required: true, message: '此欄位必填' }
        ],
        Classifications: [
          { required: true, message: '此欄位必填' }
        ],
        // Certifications: [
        //   { required: true, message: '此欄位必填' }
        // ],
      } as VxeFormPropTypes.Rules,
      formItems: [
        { field: 'Nickname', title: '姓名', span: 12, itemRender: { name: '$input', props: { placeholder: '姓名' } } },
        { field: 'Status', title: '帳號狀態', span: 12, itemRender: { name: '$select', options:[] } },
        { field: 'Account', title: '帳號', span: 12, itemRender: { name: '$input', props: { placeholder: '帳號' } } },
        { field: 'Email', title: 'Email', span: 12, itemRender: { name: '$input', props: { placeholder: 'Email' } } },
        { field: 'Password', title: '密碼', span: 12, itemRender: { name: '$input', props: { type: 'password', placeholder: '密碼' } } },
        { field: 'Password2', title: '確認密碼', span: 12, itemRender: { name: '$input', props: { type: 'password', placeholder: '確認密碼' } } },
        { field: 'Person.Phone', title: '聯絡電話', span: 12, itemRender: { name: '$input', props: { placeholder: '聯絡電話' } } },
        { field: 'Person.Fax', title: '傳真', span: 12, itemRender: { name: '$input', props: { placeholder: '傳真' } } },
        // { field: 'Person.Address.City', title: '縣市', span: 6, itemRender: { name: '$select', options:[], events: { change: changeCity } } },
        // { field: 'Person.Address.District', title: '行政區', span: 6, itemRender: { name: '$select', options:[] } },
        // { field: 'Person.Address.Line', title: '詳細地址', span: 12, itemRender: { name: '$input', props: { placeholder: '詳細地址' } } },
        { field: 'Type', title: '會員類型', span: 12, itemRender: { name: '$select', options: [], events: { change: ChangeByType } } },
        { field: 'Recommend', title: '入會推薦人', span: 12, itemRender: { name: '$input', props: { placeholder: '入會推薦人' } } },
        // { field: 'Person.Sex', title: '性別', span: 12, itemRender: { name: '$radio', options: [ { label: '女', value: 0 }, { label: '男', value: 1 }] }, visibleMethod: VisibleByType },
        { field: 'Person.MobilePhone', title: '手機', span: 12, itemRender: { name: '$input', props: { placeholder: '手機' } }, visibleMethod: VisibleByType },
        { field: 'JobTitle', title: '單位/職稱', span: 12, itemRender: { name: '$input', props: { placeholder: '單位/職稱' } }, visibleMethod: VisibleByType },
        { field: 'JobType', title: '專長', span: 12, itemRender: { name: '$input', props: { placeholder: '專長' } }, visibleMethod: VisibleByType }, //工作性質        
        { field: 'Education', title: '學歷', span: 12, itemRender: { name: '$input', props: { placeholder: '學歷' } }, visibleMethod: VisibleByType },
        { field: 'School', title: '經歷', span: 12, itemRender: { name: '$input', props: { placeholder: '經歷' } }, visibleMethod: VisibleByType }, //科系
        // { field: 'Person.BirthDate', title: '出生年月日', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '出生年月日' } }, visibleMethod: VisibleByType },
        { field: 'Organization', title: '公司名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '公司名稱' } } }, //, visibleMethod: VisibleByType
        { field: 'OrganizationEnglish', title: '公司英文名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '公司英文名稱' } }, visibleMethod: VisibleByType },
        { field: 'Tax', title: '統一編號', span: 12, itemRender: { name: '$input', props: { placeholder: '統一編號' } }, visibleMethod: VisibleByType },
        { field: 'Person.Address.Line', title: '聯絡/公司地址', span: 12, itemRender: { name: '$input', props: { placeholder: '聯絡/公司地址' } } }, //, visibleMethod: VisibleByType
        { field: 'OrgUrl', title: '網址', span: 12, itemRender: { name: '$input', props: { placeholder: '網址' } }, visibleMethod: VisibleByType },
        { field: 'Principal', title: '負責人', span: 12, itemRender: { name: '$input', props: { placeholder: '負責人' } }, visibleMethod: VisibleByType },
        { field: 'BuildTime', title: '成立日期', span: 8,  itemRender: { name: '$input', props: { type: 'date', placeholder: '成立日期' } }, visibleMethod: VisibleByType },
        { field: 'Capital', title: '資本額(萬元)', titleWidth: 80, span: 8, itemRender: { name: '$input', props: { type: 'number', placeholder: '資本額' } }, visibleMethod: VisibleByType },
        { field: 'MemberTotal', title: '員工人數', span: 8, itemRender: { name: '$input', props: { type: 'number', placeholder: '員工人數' } }, visibleMethod: VisibleByType },
        { field: 'Representative1', title: '會員代表1', span: 6, itemRender: { name: '$input', props: { placeholder: '會員代表' } }, visibleMethod: VisibleByType },
        { field: 'Phone1', title: '電話(1)', span: 6, itemRender: { name: '$input', props: { placeholder: '會員代表電話' } }, visibleMethod: VisibleByType },
        { field: 'Email1', title: 'Email(1)', span: 6, itemRender: { name: '$input', props: { placeholder: '會員代表Email' } }, visibleMethod: VisibleByType },
        { field: 'JobTitle1', title: '職稱(1)', span: 6, itemRender: { name: '$input', props: { placeholder: '會員代表職稱' } }, visibleMethod: VisibleByType },
        { field: 'Representative2', title: '會員代表2', span: 6, itemRender: { name: '$input', props: { placeholder: '會員代表' } }, visibleMethod: VisibleByType },
        { field: 'Phone2', title: '電話(2)', span: 6, itemRender: { name: '$input', props: { placeholder: '會員代表電話' } }, visibleMethod: VisibleByType },
        { field: 'Email2', title: 'Email(2)', span: 6, itemRender: { name: '$input', props: { placeholder: '會員代表Email' } }, visibleMethod: VisibleByType },
        { field: 'JobTitle2', title: '職稱(2)', span: 6, itemRender: { name: '$input', props: { placeholder: '會員代表職稱' } }, visibleMethod: VisibleByType },
        { field: 'Representative3', title: '會員代表3', span: 6, itemRender: { name: '$input', props: { placeholder: '會員代表' } }, visibleMethod: VisibleByType },
        { field: 'Phone3', title: '電話(3)', span: 6, itemRender: { name: '$input', props: { placeholder: '會員代表電話' } }, visibleMethod: VisibleByType },
        { field: 'Email3', title: 'Email(3)', span: 6, itemRender: { name: '$input', props: { placeholder: '會員代表Email' } }, visibleMethod: VisibleByType },
        { field: 'JobTitle3', title: '職稱(3)', span: 6, itemRender: { name: '$input', props: { placeholder: '會員代表職稱' } }, visibleMethod: VisibleByType },
        { field: 'Contact', title: '聯絡人', span: 8, itemRender: { name: '$input', props: { placeholder: '聯絡人' } }, visibleMethod: VisibleByType },
        { field: 'ContactEmail', title: '聯絡人Email', span: 8, itemRender: { name: '$input', props: { placeholder: '聯絡人Email' } }, visibleMethod: VisibleByType },
        { field: 'ContactJobTitle', title: '聯絡人職稱', span: 8, itemRender: { name: '$input', props: { placeholder: '聯絡人職稱' } }, visibleMethod: VisibleByType },
        { field: 'ContactPhone', title: '聯絡人電話', span: 8, itemRender: { name: '$input', props: { placeholder: '聯絡人電話' } }, visibleMethod: VisibleByType },
        { field: 'ContactFax', title: '聯絡人傳真', span: 8, itemRender: { name: '$input', props: { placeholder: '聯絡人傳真' } }, visibleMethod: VisibleByType },
        { field: 'ContactAddress', title: '聯絡人地址', span: 8, itemRender: { name: '$input', props: { placeholder: '聯絡人地址' } }, visibleMethod: VisibleByType },
        { field: 'MainItem', title: '主要營業項目', titleWidth: 120, span: 12, itemRender: { name: '$input', props: { placeholder: '主要營業項目' } }, visibleMethod: VisibleByType },
        { field: 'Technology', title: '主要技術', span: 12, itemRender: { name: '$input', props: { placeholder: '主要技術' } }, visibleMethod: VisibleByType },
        { field: 'Overseas1', title: '海外事業(1)', span: 12, itemRender: { name: '$input', props: { placeholder: '海外事業(1)' } }, visibleMethod: VisibleByType },
        { field: 'Overseas2', title: '海外事業(2)', span: 12, itemRender: { name: '$input', props: { placeholder: '海外事業(2)' } }, visibleMethod: VisibleByType },
        { field: 'Overseas3', title: '海外事業(3)', span: 12, itemRender: { name: '$input', props: { placeholder: '海外事業(3)' } }, visibleMethod: VisibleByType },
        { field: 'Overseas4', title: '海外事業(4)', span: 12, itemRender: { name: '$input', props: { placeholder: '海外事業(4)' } }, visibleMethod: VisibleByType },
        { field: 'Classifications', title: '營業性質', span: 24, itemRender: { name: '$checkbox', options: [] }, visibleMethod: VisibleByType },
        { field: 'Certifications', title: '國際認證', span: 24, itemRender: { name: '$checkbox', options: [] }, visibleMethod: VisibleByType },
        { field: 'Other', title: '營業性質-其它', span: 12, itemRender: { name: '$input', props: { placeholder: '營業性質-其它' } }, visibleMethod: VisibleByType },
        { field: 'CertificationOther', title: '國際認證-其它', span: 12, itemRender: { name: '$input', props: { placeholder: '國際認證-其它' } }, visibleMethod: VisibleByType },
        { field: 'Introduction', title: '公司簡介', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 6, maxRows: 6 }, placeholder: '內容' } }, visibleMethod: VisibleByType },
        { align: 'right', span: 24, titleAlign: 'left', itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確認', status: 'primary' } } , { props: { type: 'reset', content: '重置' } }] } } //, events: { click: save }
      ] as VxeFormPropTypes.Items
    });

    const passwordCheck = (val: boolean) => {
      form.formRules['Password'][0].required = val
      form.formRules['Password2'][0].required = val
    }

    const cityInit = () =>{      
      form.DistrictsList = districtList('台北市');
      var districtOption = form.formItems[9];      
      if (districtOption.itemRender) {    
        form.formData.Person.Address.City = form.CityList[0]['value'],    
        districtOption.itemRender.options = form.DistrictsList;
        form.formData.Person.Address.District = form.DistrictsList[0]['value']              
      }
    };

    const getClassification = () => {
        return new Promise((resolve, reject) => {
          model?.dispatch('classification/query', { keyword: null, skipRows: null, takeRows: null, simple: true }).then(
            response => resolve(response), 
            reason =>  reject(reason)
          );
      });
    };

    const getCertification = () => {
        return new Promise((resolve, reject) => {
          model?.dispatch('certification/query', { keyword: null, skipRows: null, takeRows: null, simple: true }).then(
            response => resolve(response), 
            reason =>  reject(reason)
          );
      });
    };

    onMounted(() => {
      const memberStatusEnum: any = Object.values(model!.enums.MemberStatus).map(e => { return { label: e.Name, value: e.Value } })
      var item = form.formItems[1];          
      if (item.itemRender && memberStatusEnum.length > 0) {            
        form.StatusList = memberStatusEnum; 
        item.itemRender.options = memberStatusEnum;                  
      }

      const memberTypeEnum: any = Object.values(model!.enums.MemberType).map(e => { return { label: e.Name, value: e.Value } })
      var item = form.formItems[8];     //11
      if (item.itemRender && memberTypeEnum.length > 0) {            
        form.TypeList = memberTypeEnum; 
        item.itemRender.options = memberTypeEnum;                  
      }

      getClassification().then((data: any) =>{
        item = form.formItems[48]; //41
        if (item.itemRender && data.length > 0) {
          item.itemRender.options = data;
          form.ClassificationList = data;
        }
      });

      getCertification().then((data: any) =>{
        item = form.formItems[49];
        if (item.itemRender && data.length > 0) {
          item.itemRender.options = data;
          form.CertificationList = data;
        }
      });

      // form.CityList = cityList();  
      // var citytOption = form.formItems[8];
      // if (citytOption.itemRender) citytOption.itemRender.options = form.CityList;
      // cityInit();

    })

    const UploadPhotoFiles = (type: any) => {
      var obj = type == 1 ? file1.value : file2.value;
      const formData = new FormData();
      if(obj.length > 0){
        var file = obj[0]['file'];
        var limit = 1024 * 1024 * 25;
        if(file['size'] >= limit) {
          CloudFun.send('error', { subject: '上傳失敗！', content: '檔案大小不得超過25M' })
          return;
        }
        console.log(file['name'])
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(file['name'])) {
          CloudFun.send('error', { subject: '上傳失敗！', content: '不支援此檔案類型' })
          return;
        }
        formData.append("files", file);
        model?.dispatch(`member/uploadPicture`, { formData: formData, id: form.formData.Id.toString(), type: type }).then(
          () => {          
            CloudFun.send('info', { subject: '新增成功', content: '新增圖片完成' })
            ReloadFiles(type)
          },
          failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
        ).finally(() => {

        });
      }
    }

    const ReloadFiles = (type: any) => {
      model?.dispatch('member/find', form.formData.Id).then(
        paylod => {
          if(type == 1 && paylod.ProductPhoto1) { 
            form.formData.ProductPhoto1 = paylod.ProductPhoto1; 
            file1.value = []; 
            if(form.formData.ProductPhoto1.Uri) form.formData.ProductPhoto1.Uri = form.formData.ProductPhoto1.Uri + "?" + new Date().getTime(); ;
          }
          if(type == 2 && paylod.ProductPhoto2) { 
            form.formData.ProductPhoto2 = paylod.ProductPhoto2; 
            file2.value = []; 
            if(form.formData.ProductPhoto2.Uri) form.formData.ProductPhoto2.Uri = form.formData.ProductPhoto2.Uri + "?" + new Date().getTime(); ;
          }
        },
        failure => CloudFun.send('error', { subject: '附件讀取失敗', content: failure.message })
      );
    }

    return {
      UploadPhotoFiles,
      selectdCity,
      form,
      grid,
      gridOptions,
      loading,
      isEditing,
      editingRow,
      isRowSelected,
      keyword,
      customFilters,
      submitSave,
      cityInit,
      changeCity,
      passwordCheck,
      VisibleByType,
      ChangeByType,
      uploader1,
      uploader2,
      file1,
      file2,
      ReloadFiles,
      selectdType
    };
  },
  methods: {
    reset() {      
      this.form.formData.Id = "00000000-0000-0000-0000-000000000000";
      this.form.formData.Status = 0;
      this.form.formData.StatusString = '';
      this.form.formData.Account = '';
      this.form.formData.Password = '';
      this.form.formData.Password2 = '';
      this.form.formData.CreatedTimeString = '';
      this.form.formData.CreatedTime = '';
      this.form.formData.Remark = '';
      this.form.formData.Nickname = '';
      this.form.formData.Email = '';
      this.form.formData.JobTitle = '';
      this.form.formData.JobType = '';
      this.form.formData.Unit = '';
      this.form.formData.Education = '';
      this.form.formData.Type = 0;
      this.form.formData.School = '';
      this.form.formData.Person.Id = 0;
      this.form.formData.Person.Fax = '';
      this.form.formData.Person.Sex = 0;
      this.form.formData.Person.BirthDate = '';
      this.form.formData.Person.Phone = '';
      this.form.formData.Person.MobilePhone = '';
      this.form.formData.Person.Address.Id = 0;
      this.form.formData.Person.Address.Line = '';
      this.form.formData.Person.Address.PostalCode = '';
      this.form.formData.Classifications = [];
      this.form.formData.Certifications = [];
      this.form.formData.Organization= '';
      this.form.formData.OrganizationEnglish= '';
      this.form.formData.Tax= '';
      this.form.formData.OrgUrl= '';
      this.form.formData.Principal= '';
      this.form.formData.BuildTime= '';
      this.form.formData.Capital= 0;
      this.form.formData.MemberTotal= 0;
      this.form.formData.Representative1= '';
      this.form.formData.Email1= '';
      this.form.formData.JobTitle1= '';
      this.form.formData.Representative2= '';
      this.form.formData.Email2= '';
      this.form.formData.JobTitle2= '';
      this.form.formData.Representative3= '';
      this.form.formData.Email3= '';
      this.form.formData.JobTitle3= '';      
      this.form.formData.Email3= '';
      this.form.formData.JobTitle3= '';
      this.form.formData.Phone1= '';
      this.form.formData.Phone2= '';
      this.form.formData.Phone3= '';
      this.form.formData.Contact= '';
      this.form.formData.ContactEmail= '';
      this.form.formData.ContactJobTitle= '';
      this.form.formData.MainItem= '';
      this.form.formData.Technology= '';
      this.form.formData.Other= '';
      this.form.formData.CertificationOther= '';
      this.form.formData.Introduction= '';
      this.form.formData.Recommend = '';
      this.form.formData.Overseas1 = '';
      this.form.formData.Overseas3 = '';
      this.form.formData.Overseas2 = '';
      this.form.formData.Overseas4 = '';
      if(this.file1.length > 0) this.file1 = []
      if(this.file2.length > 0) this.file2 = []
      this.form.formData.ProductPhoto1 = { Uri:'', Id: 0, }
      this.form.formData.ProductPhoto2 = { Uri:'', Id: 0, }  
      this.form.formData.ContactPhone = '';
      this.form.formData.ContactFax = '';
      this.form.formData.ContactAddress = '';
      //this.cityInit();
      //this.form.formData.Address.City = this.form.CityList[0]['value'];
      //this.form.formData.Address.District = this.form.DistrictsList[0]['value'];       
    },
    edit(row: any) {      
      this.$model.dispatch('member/find', row.Id).then(
        paylod => {
          Object.assign(this.form.formData, paylod)          
          this.form.model = 'edit';
          this.form.formData.Password = '';
          this.form.formData.Password2 = '';
          this.file1 = [];
          this.file2 = [];
          if(this.form.formData.ProductPhoto1.Uri) { 
            setTimeout(() => {
              this.form.formData.ProductPhoto1.Uri = this.form.formData.ProductPhoto1.Uri + "?" + new Date().getTime(); 
            }, 200);
          }
          if(this.form.formData.ProductPhoto2.Uri) { 
            setTimeout(() => {
              this.form.formData.ProductPhoto2.Uri = this.form.formData.ProductPhoto2.Uri + "?" + new Date().getTime(); 
            }, 200);
          }
          //this.defaultDistrict(this.form.formData.Person.Address.District);
          this.form.showEdit = true;
          this.passwordCheck(false);
          this.ChangeByType();
        },
        failure => this.$send('error', { subject: '操作失敗！', content: failure.message })
      );
    },
    addNewRow() {
      this.reset();
      this.form.model = 'insert';
      this.passwordCheck(true);  
      this.form.showEdit = true;
      this.ChangeByType();
    },
    save(row?: any) {
      const { insertRecords, updateRecords, removeRecords } = this.grid.getRecordset();
      let needSave = insertRecords.length || updateRecords.length || removeRecords.length;
      needSave &&= row && (insertRecords.some(e => e._XID === row._XID) || updateRecords.some(e => e._XID === row._XID) || removeRecords.some(e => e._XID === row._XID));   
      const isRowEditing = row && this.editingRow === row;
      if (needSave) {
        this.grid.commitProxy('save').then(
          () => {
            if (isRowEditing && this.editingRow === row) this.edit(row);
            else this.editingRow = null;
          }
        );
      } else if (!row || row === this.editingRow) this.editingRow = null;
    },
    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch('member/delete', row.Id).then(
          () => { this.grid.commitProxy('query').finally(() => { this.gridOptions.loading = false; }); },
          failure => this.$send('error', { subject: '會員刪除失敗', content: failure.message })
        );
      }
    },
    removeSelectedRows() {
      cash('#batch-dropdown').dropdown('hide');
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy('delete');
      }
    },
    changeFilterEvent(event: Event, option: VxeColumnPropTypes.Filter, panel: any, toogleChecked?: boolean) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value) option.value = new Condition('Name', Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked) panel.changeOption(event, !!option.data, option)
    },
    defaultDistrict(district: string) {      
      this.form.DistrictsList = districtList(this.form.formData.Person.Address.City); 
      var districtOption = this.form.formItems[9];      
      if (districtOption.itemRender) {
        districtOption.itemRender.options = this.form.DistrictsList;        
        this.form.formData.Person.Address.District = district ?? this.form.DistrictsList[0]['value'];
      }
    },
    inputFilter1(newFile: any, oldFile: any, prevent: any) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          //this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        const URL = window.URL || window.webkitURL        
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
          this.form.formData.ProductPhoto1.Uri = newFile.url
        }
      }
    },
    inputFilter2(newFile: any, oldFile: any, prevent: any) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          //this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        const URL = window.URL || window.webkitURL        
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
          this.form.formData.ProductPhoto2.Uri = newFile.url
        }
      }
    },
    async onExport(type: 'xlsx' | 'html', mode: 'current' | 'selected' | 'all' = 'all') {
      const config: any = { ...this.gridOptions.exportConfig, type, mode };
      if (mode === 'all') {
        const params = { 
          keyword: this.keyword,
          type: this.selectdType,
          sortings: [new Sorting("CreatedTime", 1)], // sorts
        };
        config.data = await this.$model.dispatch('member/query', params);
      }
      this.grid.exportData({ ...config, type, mode });
    },
    async onPrint(mode: 'current' | 'selected' | 'all' = 'all') {
      const config: any = { ...this.gridOptions.printConfig, mode };
      if (mode === 'all') config.data = await this.$model.dispatch('member/query');
      this.grid.print(config);
    },
    inputFile(newFile: any, oldFile: any, prevent: any) {      
      
      if (newFile && !oldFile) {
        this.$nextTick(function () {          
        })
      }
      if (!newFile && oldFile) {        
      }
    },
    deletePicture(type: any) {
      if((this.form.formData.ProductPhoto1.Id == 0 && type == 1) || (this.form.formData.ProductPhoto2.Id == 0 && type == 2)) {
        if(this.file1.length > 0 || this.file2.length > 0) this.removeFile(type)
        return;
      }
      this.$model.dispatch(`picture/deletePicture`, type == 1 ? this.form.formData.ProductPhoto1.Id : this.form.formData.ProductPhoto2.Id).then(
        paylod => {
          CloudFun.send('info', { subject: '刪除成功！', content: '圖片刪除成功' })
          this.ReloadFiles(type)
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure})
      ).finally(() => {
      });
    },
    removeFile(type: any) {
      if(this.file1.length > 0 && type == 1){
        this.file1 = [];
        this.form.formData.ProductPhoto1.Uri = '';
      }
      if(this.file2.length > 0 && type == 2){
        this.file2 = [];
        this.form.formData.ProductPhoto2.Uri = '';
      }
    },
  }
});
</script>
