<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">商情諮詢主題</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button v-show="!isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid 
          ref="grid" 
          v-bind="gridOptions" 
          @addNewRow="(row, callback) => { initData(row); callback(); }"
          @edit="onGridEdit"
          @modalResize="onGridModalResize"
          @removeSelectedRows="onGridRemoveSelectedRows" 
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form :title-width="100" title-align="right" v-bind="formOptions" :data="row" @reset="reset" @submit="submit">
            </vxe-form>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
  .swiper-container {
    padding-top: 5px;
    padding-bottom: 30px;
  }
</style>
<style>
  textarea {
    min-height: 140px;  
  }
</style>

<script>
import { onMounted } from "vue";
import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";

export default defineComponent({
  components:{
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;

    const grid = ref({});

    var printColumns = [
      { field: 'ForumTopicString' }, 
      { field: 'Title' }, 
      { field: 'Name' }, 
      { field: 'EnabledString' },
      { field: 'PublishedString' },
      { field: 'Ordinal' },
    ]

    const gridOptions = {
      title: '商情諮詢主題',
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      sortConfig: { defaultSort: [{ field: 'Ordinal', orderby: 'asc' }, { field: 'CreatedTime', order: 'desc' }] },
      printConfig: { sheetName: '商情諮詢主題清單', columns: printColumns, modes: [ 'current', 'selected', 'all' ] },
      exportConfig: { filename: '商情諮詢主題清單', type: 'csv', types: [ 'html', 'csv' ], mode: 'all', modes: [ 'current', 'selected', 'all' ], columns: printColumns },
      columns: [
        {
          field: 'CategoryId', title: '分類名稱', sortable: true, width: 200, formatter: ({ cellValue }) => { return form.ForumCategoty ? form.ForumCategoty?.find(e => e.value === cellValue)?.label : ""; }
        },
        { 
          field: 'Title', title: '標題', showHeaderOverflow: true, showOverflow: true, sortable: true,
          editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        },    
        {
          field: 'Name', title: '姓名', sortable: true, width: 150
        },    
        { field: "Published", title: "發布", width: 80, sortable: true, formatter: ({ cellValue }) => { return cellValue ? '是' : '否' } },    
        {
          field: 'Ordinal', title: '排序', width: 80, sortable: true,
        },
      ],
      promises: {
        query: model ? (params) => { if(params.sortings.length === 0) params.sortings.push({ column: 'Ordinal', orderby: 0 }, { column: 'CreatedTime', order: 1 }); return model.dispatch('forum/query', params) }: undefined,
        queryAll: model ? () => model.dispatch('forum/query', { keyword: grid.value.keyword, type: 2 }) : undefined,
        save: model ? (params) => model.dispatch('forum/save', params) : undefined
      },
      modalConfig: { height: 550 }
    };
    const formOptions = {
      items: [
        {
          field: 'CategoryId', title: '分類', span: 12, itemRender: { name: '$select', options:[] },
        },
        {
          field: 'MemberId', title: '會員', span: 12, itemRender: { name: '$select', options:[] },
        },
        {
          field: 'Title', title: '標題', span: 12, 
          itemRender: { name: '$input', props: { placeholder: '請輸入標題', clearable: true } },
        },
        { field: 'Published', title: '發佈', span: 12, itemRender: { name: '$radio', options:[{ label: '是', value: true }, { label: '否', value: false }] } },
        { field: 'Enabled', title: '熱門', span: 12, itemRender: { name: '$radio', options:[{ label: '是', value: true }, { label: '否', value: false }] } },
        { field: 'PublishDate', title: '發佈日期', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '發佈開始日期' } } },
        {
          field: 'Ordinal', title: '排序', span: 12, 
          itemRender: { name: '$input', props: { type:'number', placeholder: '請輸入排序', clearable: true } },
        },  
        {
          field: 'Name', title: '姓名', span: 12, 
          itemRender: { name: '$input', props: { placeholder: '請輸入姓名', clearable: true } },
        },
        {
          field: 'Email', title: 'Email', span: 12, 
          itemRender: { name: '$input', props: { placeholder: '請輸入Email', clearable: true } },
        },
        { field: 'Content', title: '內容', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 6, maxRows: 6 }, placeholder: '內容' } } },
        {
          span: 24, align: "right", 
          itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確定', status: 'primary' } }, { props: { type: 'reset', content: '重置' } }] }
        }
      ], 
      rules: { 
        Title: [{ type: 'string', max: 128, message: '請輸入標題', required: true }],
        Name: [{ type: 'string', max: 128, message: '請輸入姓名', required: true }],
        Ordinal: [{ type: 'number', required: true }],
        Published: [{ required: true }],
        CategoryId: [{ required: true, message: '請輸入分類' }],
        MemberId: [{ required: true, message: '請輸入會員' }],
        Enabled: [{ required: true }],
        PublishDate: [{ required: true }],
        Content: [{ required: true, message: '請輸入內容' }],
        Email: [{ required: true, message: '請輸入正確Email', pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
      }
    };

    const initData = (row) => {
      row.Published = false;
      row.Enabled = false;
      row.Ordinal = 0;
      row.Type = 2;
    }

    const getMember = () => {
        return new Promise((resolve, reject) => {
          model.dispatch('member/query', { keyword: null, skipRows: null, takeRows: null, simple: true }).then(
            response => resolve(response), 
            reason =>  reject(reason)
          );
      });
    };

    const getForumCategoty = () => {
        return new Promise((resolve, reject) => {
          model.dispatch('forumCategory/query', { keyword: null, skipRows: null, takeRows: null, simple: true, type: 2 }).then(
            response => resolve(response), 
            reason =>  reject(reason)
          );
      });
    };

    const form = reactive({
      ForumCategoty:[]
    });

    onMounted(() => {
      getMember().then((data) =>{  
        var item = formOptions.items[1]; 
        if (item.itemRender && data.length > 0) {
          item.itemRender.options = data;
        }
      });
      getForumCategoty().then((data) =>{  
        var item = formOptions.items[0]; 
        if (item.itemRender && data.length > 0) {
          form.ForumCategoty = data;
          item.itemRender.options = data;
        }
      });
      grid.value.type = 2;
    })

    return {
      initData,
      grid,
      form,
      gridOptions,
      formOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    onGridModalResize(size) {
      this.sliderWidth = `${size.width-100}px`;
    },
    async onGridEdit(row, callback) {
      const entity = row.Id ? await this.$model.dispatch('forum/find', row.Id) : undefined;
      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows, callback) {
      cash('#batch-dropdown').dropdown('hide');
      callback();
    }
  },
});
</script>
