
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave,
} from "./index";
import context from "@cloudfun/core";

export default defineComponent({
  props: {
    adminTitle: String
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const formattedMenu = ref([]);
    const policy = context.current?.policy;
    const mobileMenu = computed(() => policy?.makeMenu(policy.sitemap?.subNodes || [], undefined, false) || []);

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(mobileMenu.value);
      }
    );

    onMounted(() => {
      formattedMenu.value = $h.toRaw(mobileMenu.value);
    });

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
    };
  },
});
