<template>
  <div class="intro-y mt-8">
    <h2 class="text-lg font-medium mr-auto">系統配置</h2>
    <form class="validate-form" @submit.prevent="save">
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="col-span-12 lg:col-span-6">
          <!-- BEGIN: Form Layout -->
          <div class="intro-y box p-5 h-full">
            <div>
              <label>網站標題</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{
                    'border-theme-6': validate.PortalDefaultTitle.$error,
                  }"
                >
                  <FontAwesome icon="quote-right" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.PortalDefaultTitle.$model"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{
                    'border-theme-6': validate.PortalDefaultTitle.$error,
                  }"
                  placeholder="請輸入標題"
                  name="PortalDefaultTitle"
                />
              </div>
              <template v-if="validate.PortalDefaultTitle.$error">
                <div
                  v-for="(error, index) in validate.PortalDefaultTitle.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>網站後台標題</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{
                    'border-theme-6': validate.AdminTitle.$error,
                  }"
                >
                  <FontAwesome icon="quote-right" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.AdminTitle.$model"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{
                    'border-theme-6': validate.AdminTitle.$error,
                  }"
                  placeholder="請輸入標題"
                  name="AdminTitle"
                />
              </div>
              <template v-if="validate.AdminTitle.$error">
                <div
                  v-for="(error, index) in validate.AdminTitle.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>網站關鍵字</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{
                    'border-theme-6': validate.PortalDefaultMetaKeywords.$error,
                  }"
                >
                  <FontAwesome icon="quote-right" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.PortalDefaultMetaKeywords.$model"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{
                    'border-theme-6': validate.PortalDefaultMetaKeywords.$error,
                  }"
                  placeholder="請輸入關鍵字，並以逗號隔開"
                  name="PortalDefaultMetaKeywords"
                />
              </div>
              <template v-if="validate.PortalDefaultMetaKeywords.$error">
                <div
                  v-for="(error, index) in validate.PortalDefaultMetaKeywords
                    .$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>網站描述</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{
                    'border-theme-6':
                      validate.PortalDefaultMetaDescription.$error,
                  }"
                >
                  <FontAwesome icon="quote-right" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.PortalDefaultMetaDescription.$model"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{
                    'border-theme-6':
                      validate.PortalDefaultMetaDescription.$error,
                  }"
                  placeholder="請輸入描述文字"
                  name="PortalDefaultMetaDescription"
                />
              </div>
              <template v-if="validate.PortalDefaultMetaDescription.$error">
                <div
                  v-for="(error, index) in validate.PortalDefaultMetaDescription
                    .$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>負責人</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.Principal.$error }"
                >
                  <FontAwesome icon="address-card" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.Principal.$model"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{ 'border-theme-6': validate.Principal.$error }"
                  placeholder="請輸入電話號碼"
                  name="Principal"
                />
              </div>
              <template v-if="validate.Principal.$error">
                <div
                  v-for="(error, index) in validate.Principal.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>聯絡電話</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.ServicePhone.$error }"
                >
                  <FontAwesome icon="phone" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.ServicePhone.$model"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{ 'border-theme-6': validate.ServicePhone.$error }"
                  placeholder="請輸入電話號碼"
                  name="ServicePhone"
                />
              </div>
              <template v-if="validate.ServicePhone.$error">
                <div
                  v-for="(error, index) in validate.ServicePhone.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>傳真電話</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.CompanyFax.$error }"
                >
                  <FontAwesome icon="fax" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.CompanyFax.$model"
                  type="text"
                  :class="{ 'border-theme-6': validate.CompanyFax.$error }"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入電話號碼"
                  name="CompanyFax"
                />
              </div>
              <template v-if="validate.CompanyFax.$error">
                <div
                  v-for="(error, index) in validate.CompanyFax.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>聯絡地址</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.CompanyAddress.$error }"
                >
                  <FontAwesome type="fas" icon="address-card" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.CompanyAddress.$model"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{ 'border-theme-6': validate.CompanyAddress.$error }"
                  placeholder="請輸入地址"
                  name="CompanyAddress"
                />
              </div>
              <template v-if="validate.CompanyAddress.$error">
                <div
                  v-for="(error, index) in validate.CompanyAddress.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>聯絡Email</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.ServiceEmail.$error }"
                >
                  <FontAwesome icon="at" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.ServiceEmail.$model"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{ 'border-theme-6': validate.ServiceEmail.$error }"
                  placeholder="請輸入Email"
                  name="ServiceEmail"
                />
              </div>
              <template v-if="validate.ServiceEmail.$error">
                <div
                  v-for="(error, index) in validate.ServiceEmail.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
          </div>
          <!-- END: Form Layout -->
        </div>
        <div class="intro-y col-span-12 lg:col-span-6">
          <!-- BEGIN: Form Layout -->
          <div class="intro-y box p-5 h-full">
            <div class="mt-3">
              <label>Facebook</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.Facebook.$error }"
                >
                  <FontAwesome icon="facebook" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.Facebook.$model"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{ 'border-theme-6': validate.Facebook.$error }"
                  placeholder="請輸入URL"
                  name="Facebook"
                />
              </div>
              <template v-if="validate.Facebook.$error">
                <div
                  v-for="(error, index) in validate.Facebook.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>Instagram</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.Instagram.$error }"
                >
                  <FontAwesome icon="instagram" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.Instagram.$model"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{ 'border-theme-6': validate.Instagram.$error }"
                  placeholder="請輸入URL"
                  name="Instagram"
                />
              </div>
              <template v-if="validate.Instagram.$error">
                <div
                  v-for="(error, index) in validate.Instagram.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>Twitter</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.Twitter.$error }"
                >
                  <FontAwesome icon="twitter" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.Twitter.$model"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{ 'border-theme-6': validate.Twitter.$error }"
                  placeholder="請輸入URL"
                  name="Twitter"
                />
              </div>
              <template v-if="validate.Twitter.$error">
                <div
                  v-for="(error, index) in validate.Twitter.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>LINE</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.LINE.$error }"
                >
                  <FontAwesome icon="line" class="w-6" />
                </div>
                <input
                  v-model.trim="validate.LINE.$model"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{ 'border-theme-6': validate.LINE.$error }"
                  placeholder="http://line.me/ti/p/@"
                  name="LINE"
                />
              </div>
              <template v-if="validate.LINE.$error">
                <div
                  v-for="(error, index) in validate.LINE.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>第三方網站追蹤代碼</label>
              <textarea
                v-model.trim="validate.TrackingCode.$model"
                class="input w-full border mt-2 h-44"
                :class="{ 'border-theme-6': validate.TrackingCode.$error }"
                placeholder="請輸入追蹤代碼"
              ></textarea>
              <template v-if="validate.TrackingCode.$error">
                <div
                  v-for="(error, index) in validate.TrackingCode.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
          </div>
          <!-- END: Form Layout -->
        </div>
        <div class="intro-y col-span-12 lg:col-span-12">
          <div class="text-right">
            <button type="submit" class="button w-24 bg-theme-1 text-white">
              更新
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, url } from "@vuelidate/validators";
import context from "@cloudfun/core";

export default defineComponent({
  setup() {
    const application = context.root;
    const configuration = reactive({
      PortalDefaultTitle: "",
      PortalDefaultMetaKeywords: "",
      PortalDefaultMetaDescription: "",
      ServicePhone: "",
      CompanyFax: "",
      CompanyAddress: "",
      ServiceEmail: "",
      Facebook: "",
      Instagram: "",
      Twitter: "",
      LINE: "",
      TrackingCode: "",
      AdminTitle: "",
      Principal: "",
    });

    const rules = {
      PortalDefaultTitle: { required },
      PortalDefaultMetaKeywords: { required },
      PortalDefaultMetaDescription: { required },
      ServicePhone: {},
      CompanyFax: {},
      CompanyAddress: {},
      ServiceEmail: { email },
      Facebook: { url },
      Instagram: { url },
      Twitter: { url },
      LINE: { url },
      TrackingCode: {},
      Principal: {},
      AdminTitle: { required },
    };

    const validate = useVuelidate(rules, toRefs(configuration));

    const save = () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        context.send("warning", {
          subject: "資料有誤",
          content: "資料驗證失敗，請依提示進行修正後再試一次",
        });
      } else {
        application?.model.dispatch("configuration/update", configuration).then(
          () => { // success
            context.send("info", {
              subject: "更新成功",
              content: "系統配置已更新"
            });
          }, 
          failure => {
            context.send("error", {
              subject: "更新失敗",
              content: failure.message
            })
          }
        );
      }
    };

    onMounted(() => {
      application?.model.dispatch("configuration/read").then(value => {
        Object.assign(configuration, value);
      });
    });

    return {
      configuration,
      validate,
      save,
    };
  },
});
</script>
