<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">檔案管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button v-show="!isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
            <select class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model="selectdType" @change="grid.refresh">
              <option value="">請選擇分類</option>
              <option v-for="item in form.Category" :value="item.value" :key="item.value">{{ item.label }}</option>
            </select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>        
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid 
          ref="grid" 
          v-bind="gridOptions" 
          @addNewRow="(row, callback) => { initData(row); callback(); }"
          @edit="onGridEdit"
          @modalResize="onGridModalResize"
          @removeSelectedRows="onGridRemoveSelectedRows" 
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form :title-width="60" title-align="right" v-bind="formOptions" :data="row" @reset="reset" @submit="UploadFiles(row, submit)">
              
              <template #file-upload>
                <div class="p-2 border">
                  <div v-if="form.mode == 'insert'">                   
                    <div class="mb-2">選擇檔案</div>
                    <div>
                      <div>檔案名稱：<span v-if="AttachmentFiles.length > 0">{{ AttachmentFiles[0]['file']['name'] }}</span></div>
                      <div>檔案大小：<span v-if="AttachmentFiles.length > 0">{{ AttachmentFiles[0]['file']['size'] }}</span></div>
                    </div>
                    <file-upload
                        class="mt-2"
                        :multiple="false"
                        v-model="AttachmentFiles"
                        @input-file="inputFile"
                        ref="AttachmentUploader">
                        <button type="button" class="button rounded-lg bg-theme-1 text-white">檔案上傳</button>
                    </file-upload>
                  </div>
                  <div v-if="form.mode == 'update'" class="m-2">
                    <div class="mb-3">檔案下載</div>
                    <a :href="row.Uri" target="_blank" class="button rounded-lg bg-theme-1 text-white">下載檔案</a>
                  </div>
                  </div>
              </template>
            </vxe-form>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
  .swiper-container {
    padding-top: 5px;
    padding-bottom: 30px;
  }
</style>

<script>
/* eslint-disable */
import { onMounted } from "vue";
import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor"
import FileUpload from 'vue-upload-component';

export default defineComponent({
  components:{
    Grid,
    FileUpload
  },
  setup() {
    const model = CloudFun.current?.model;

    const grid = ref({});
    const AttachmentUploader = ref({});
    const AttachmentFiles = ref([]);
    const selectdType = ref("");

    var printColumns = [
      { field: 'Title' }, 
      { field: 'Ordinal' },
      { field: 'PublishedString' },
    ]

    const gridOptions = {
      showEditRow: true,
      canDownload: false,
      title: '檔案管理',
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      sortConfig: { defaultSort: [{ field: 'Ordinal', orderby: 'asc' }, { field: 'CreatedTime', order: 'desc' }] },
      printConfig: { sheetName: '檔案管理', columns: printColumns, modes: [ 'current', 'selected', 'all' ] },
      exportConfig: { filename: '檔案管理', type: 'csv', types: [ 'html', 'csv' ], mode: 'all', modes: [ 'current', 'selected', 'all' ], columns: printColumns },
      columns: [
        {
          field: 'CategoryId', title: '分類名稱', sortable: true,
          formatter: ({ cellValue }) => { return form.Category ? form.Category?.find(e => e.value === cellValue)?.label : ""; }
        },        
        {
          field: 'FileName', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true,
          editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        },
        {
          field: 'Ordinal', title: '排序', sortable: true,
        },
      ],
      promises: {
        query: model ? (params) => { 
          params.type = selectdType.value;
          if(params.sortings.length === 0) params.sortings.push({ column: 'Ordinal', orderby: 0 }, { column: 'CreatedTime', order: 1 }); 
          return model.dispatch('files/query', params) }: undefined,
        queryAll: model ? () => model.dispatch('files/query') : undefined,
        save: model ? (params) => model.dispatch('files/save', params) : undefined
      },
      modalConfig: { height: 320 }
    };
    const formOptions = {
      items: [
        { field: 'CategoryId', title: '分類', span: 12, itemRender: { name: '$select', options:[] } },
        { field: 'Ordinal', title: '排序', span: 12, itemRender: { name: '$input', props: { type: 'number', placeholder: '排序' } } },
        { span: 24, slots: { default: 'file-upload' } },
        {
          span: 24, align: "right", 
          itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確定', status: 'primary' } }, { props: { type: 'reset', content: '重置' } }] }
        }
      ], 
      rules: { 
        Title: [{ type: 'string', message: '請輸入標題', max: 128, required: true }],
        Ordinal: [{ type: 'number', required: true }],
        Published: [{ required: true }],
        Source: [{ required: true }],
        CategoryId: [{ required: true }],
      }
    };

    const form = reactive({
      mode: 'insert',
      Category:[]
    });

    const initData = (row) => {
      row.Published = false; 
      row.Ordinal = 0; 
      row.Type = 0; 
      row.Files = [];
      form.mode = 'insert';      
    }

    const UploadFiles = (data, submit) => {
      if(form.mode == 'update') {
        submit();
        return;
      }
      var obj = AttachmentFiles.value;
      const formData = new FormData();
      if(obj.length > 0) {
        var file = obj[0]['file'];
        var limit = 1024 * 1024 * 25;
        if(file['size'] >= limit) {
          CloudFun.send('error', { subject: '附件上傳失敗！', content: '檔案大小不得超過25M' })
          return;
        }
        if (!/\.(gif|jpg|jpeg|png|webp|zip|rar|doc|docx|ppt|pptx|xlsx|xls|csv|txt|pdf)$/i.test(file['name'])) {
          CloudFun.send('error', { subject: '附件上傳失敗！', content: '不支援此檔案類型' })
          return;
        }
        formData.append("files", file);

        model?.dispatch(`files/insert`, { formData: formData, id: data.CategoryId.toString(), ordinal: data.Ordinal.toString(), mode: 'other', name: 'files' }).then(
          () => {          
            CloudFun.send('info', { subject: '新增成功', content: '新增附件完成' })
            ReloadFiles()
          },
          failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
        ).finally(() => {
        });
      } else {
        CloudFun.send('error', { subject: '新增失敗', content: '請選擇上傳檔案' })
      }
    }

    const ReloadFiles = () => {
      AttachmentFiles.value = []
      grid.value.instance.commitProxy('query').finally(() => {  
        grid.value.isModalPopup = false;
        grid.value.loading = false;
        grid.value.editingRow = null;
      });
      // model?.dispatch('files/find', data.Id).then(
      //   paylod => {
      //     AttachmentFiles.value = []
      //     if(paylod.Files && paylod.Files.length > 0) { 
      //       data.Files = paylod.Files;
      //     } else { data.Files = [] }
      //   },
      //   failure => CloudFun.send('error', { subject: '附件讀取失敗', content: failure.message })
      // );
    }



    const getCategory = () => {
        return new Promise((resolve, reject) => {
          model.dispatch('fileCategory/query', { keyword: null, skipRows: null, takeRows: null, simple: true, type: 1 }).then(
            response => resolve(response), 
            reason =>  reject(reason)
          );
      });
    };

    onMounted(() => {
      getCategory().then((data) =>{  
        var item = formOptions.items[0]; 
        if (item.itemRender && data.length > 0) {
          item.itemRender.options = data;
          form.Category = data; 
        }
      });
    })

    return {
      grid,
      form,
      gridOptions,
      formOptions,
      selectdType,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      AttachmentUploader,
      AttachmentFiles,
      ReloadFiles,
      UploadFiles,
      initData,
    };
  },
  methods: {
    onGridModalResize(size) {
      this.sliderWidth = `${size.width-100}px`;
    },
    async onGridEdit(row, callback) {
      const entity = row.Id ? await this.$model.dispatch('files/find', row.Id) : undefined;      
      if (entity) {      
        this.form.mode = 'update';  
        Object.assign(row, entity);        
      }
      callback();
    },
    onGridRemoveSelectedRows(rows, callback) {
      cash('#batch-dropdown').dropdown('hide');
      callback();
    },
    inputFile(newFile, oldFile, prevent) {      
      
      if (newFile && !oldFile) {
        this.$nextTick(function () {          
        })
      }
      if (!newFile && oldFile) {        
      }
    },
    deleteFile(id, row) {
      this.$model.dispatch(`files/delete`, id).then(
        () => {
          CloudFun.send('info', { subject: '刪除成功！', content: '附件刪除成功' })
          this.ReloadFiles(row)
        },
        failure => CloudFun.send('error', { subject: '附件刪除失敗！', content: failure})
      ).finally(() => {
      });
    },
  },
});
</script>
