<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :class="$props.class"
    :viewBox="`0 0 ${width} ${height}`"
  >
    <path fill="currentColor" :d="svgPath" />
  </svg>
</template>

<script>
import { defineComponent, computed } from "vue";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";

export default defineComponent({
  name: "FontAwesomeIcon",

  props: {
    icon: {
      type: String,
      required: true
    },
    type: {
      type: String
    },
    class: String
  },

  setup(props) {
    const definition = computed(() => {
        const prefix = ['facebook', 'instagram', 'twitter', 'line'].includes(props.icon) ? "fab" : props.type;
        if (prefix) {
            return findIconDefinition({
                prefix,
                iconName: props.icon
            });
        } else {
            return findIconDefinition({
                prefix: 'far',
                iconName: props.icon
            }) || findIconDefinition({
                prefix: 'fas',
                iconName: props.icon
            });
        }
    });

    const width = computed(() => definition.value.icon[0]);
    const height = computed(() => definition.value.icon[1]);
    const svgPath = computed(() => definition.value.icon[4]);

    return { width, height, svgPath };
  }
});
</script>