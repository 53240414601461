
/* eslint-disable */

import { onMounted } from "vue";
import CloudFun, { defineComponent, ref, reactive, computed, Sorting, SortOrder, Condition, Operator, LogicalConnective, Model } from "@cloudfun/core";
import { method } from "node_modules/@types/lodash";
import { VxeColumnPropTypes, VxeGridInstance, VxeGridProps, VxeFormPropTypes, Column, FormItem } from "vxe-table";

export default defineComponent({
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);    
    const loading = ref(false);
    const editingRow = ref(null);
    const keyword = ref("");    
    const customFilters = ref<Condition[]>([]);
    const isRowSelected = computed(() => (grid.value.getRadioRecord && grid.value.getRadioRecord()) || (grid.value.getCheckboxRecords && grid.value.getCheckboxRecords().length > 0));
    const isEditing = computed(() => editingRow.value != null && grid.value.isActiveByRow(editingRow.value));
    var printColumns = [
      { field: 'Name' }, 
      { field: 'Account' },
      { field: 'Email' },
      { field: 'StatusString' },
      { field: 'LoginTimeString' },
    ]

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: true,
      loading: false,
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: { remote: true, multiple: true },
      //editConfig: { trigger: 'manual', mode: 'row', showIcon: false, autoClear: false },
      pagerConfig: { currentPage: 1, pageSize: 10, pageSizes: [5, 10, 20], layouts: [ 'PrevJump', 'PrevPage', 'Jump', 'PageCount', 'NextPage', 'NextJump', 'Sizes', 'Total' ] },
      printConfig: { sheetName: '使用者清單', columns: printColumns, modes: [ 'current', 'selected', 'all' ] },
      exportConfig: { filename: '使用者清單', type: 'csv', types: [ 'html', 'csv' ], mode: 'all', modes: [ 'current', 'selected', 'all' ], columns: printColumns },
      columns: [
        { type: "checkbox", width: 35, fixed: 'left', resizable: false },
        { 
          field: "Name", title: "使用者名稱", showHeaderOverflow: true, showOverflow: true, sortable: true,
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'name_filter' }
        },
        { field: "Account", title: "帳號", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "Email", title: "Email", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "StatusString", title: "狀態", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "LoginTimeString", title: "最後登入時間", showHeaderOverflow: true, showOverflow: true, resizable: false },        
        { title: "操作", width: 100, fixed: 'right', align: 'center', resizable: false, slots: { default: 'operate' } },
      ],
      proxyConfig: {
        message: false,
        sort: true, 
        filter: true, 
        props: { result: 'data', total: 'totalCount' }, 
        ajax: {
          query: (params) => {    
            //params.sorts.push({ column: Column.prototype, order: 'desc', sortBy: 'Number', property:'Number' });    
            
            const queryParams: { page: number, pageSize: number, keyword: string, sortings?: Sorting[], condition: Condition } = { 
              page: params.page.currentPage, 
              pageSize: params.page.pageSize, 
              keyword: keyword.value,
              sortings: params.sorts.filter(e => e.property).map(e => new Sorting(e.property, e.order === 'desc' ? SortOrder.Descending : SortOrder.Ascending)), // sorts
              condition: new Condition(customFilters.value) // custom filters
            };
            if(queryParams.sortings?.length === 0) queryParams.sortings?.push(new Sorting('CreatedTime', 1));
            // filters
            const filters = params.filters ? params.filters.filter(e => e.values.length) : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach(filter => { 
                const columnCondition = new Condition();
                filter.values.forEach(subFilter => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And) columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) => model?.dispatch('user/load', queryParams).then(
              payload => {
                resolve(payload)
              },
              reason => {               
                CloudFun.send('error', { subject: '讀取失敗', content: reason })
                reject(reason);
              }
            ));
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) => model?.dispatch('user/save', deleteParams).then(
              payload => resolve(payload),
              reason => {
                CloudFun.send('error', { subject: '刪除失敗', content: reason })
                reject(reason);
              }
            ));
          },
          save: (params) => {
            const saveParams = { insertRows: params.body.insertRecords, updateRows: params.body.updateRecords, deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) => model?.dispatch('user/save',  saveParams).then(
              payload => { 
                editingRow.value = null;
                resolve(payload)
              },
              reason => {
                CloudFun.send('error', { subject: '保存失敗', content: reason })
                reject(reason);
              }
            ));
          }
        }
      }
    } as VxeGridProps);

    //modal 執行 新增or修改
    const submitSave = () =>{
      var method = form.model == 'insert' ? 'insert' : 'update';
      //確認密碼
      if((form.formData.Password || form.formData.Password2) && form.formData.Password != form.formData.Password2) {
         CloudFun.send('error', { subject: '操作失敗！', content: '密碼不一致，請確認輸入！' })
        return;
      }

      gridOptions.loading = true;      

      model?.dispatch(`user/${method}`, form.formData).then(
        paylod => {
          //var sorting = new Sorting('CreatedTime', 1);
          grid.value.commitProxy('query').finally(() => {  });
          CloudFun.send('info', { subject: method == 'insert' ? '新增成功' : '更新成功', content: method == 'insert' ? '使用者新增完成' : '使用者更新完成' })
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure})
      ).finally(() => { 
        form.showEdit = false; 
        gridOptions.loading = false;
      });
    }

    const roleValid = () =>{ 
      return new Promise((resolve, reject) => {        
        if(form.formData.Roles.length > 0) resolve(null);      
        reject("請輸入權限");      
      });
    }

    const form = reactive({
      model: 'insert',
      StatusList: [],
      RolesList: [],
      selectRow: true,
      showEdit: false,
      formData: {
        Id: "00000000-0000-0000-0000-000000000000",
        CreatedTimeString: '',
        CreatedTime:'',
        Name: '',
        Status: 0,
        StatusString: '',
        Email: '',        
        Account: '',        
        Password: '',
        Password2: '',
        Remark:'',
        LoginTimeString: '',
        LogoutTimeString: '',
        LoginIp: '',        
        OnlineString: '',
        Roles: [],
        Photo:{},
        PhotoId:0,
      },
      formRules: {
        Name: [
          { required: true, message: '請輸入使用者名稱' },          
        ],
        Email: [
          { required: true, message: '請輸入Email', pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }
        ],
        Account: [
          { required: true, message: '請輸入帳號' }
        ],
        Status: [
          { required: true, message: '請輸入狀態' }
        ],        
        Password: [
          { required: false, message: '請輸入密碼(至少八位英數字、特殊符號)', pattern: new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)") }
        ],
        Password2: [
          { required: false, message: '請輸入確認密碼(至少八位英數字、特殊符號)', pattern: new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)") }
        ],
        Roles: [
          { required: true, message: '請選擇權限', validator: roleValid },          
        ],
      } as VxeFormPropTypes.Rules,
      formItems: [        
        { field: 'Name', title: '使用者名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '使用者名稱' } } },
        { field: 'Status', title: '狀態', span: 12, itemRender: { name: '$select', options:[] } },
        { field: 'Account', title: '帳號', span: 12, itemRender: { name: '$input', props: { placeholder: '帳號' } } },
        { field: 'Email', title: 'Email', span: 12, itemRender: { name: '$input', props: { placeholder: 'Email' } } },
        { field: 'Password', title: '密碼', span: 12, itemRender: { name: '$input', props: { type: 'password', placeholder: '密碼' } } },
        { field: 'Password2', title: '確認密碼', span: 12, itemRender: { name: '$input', props: { type: 'password', placeholder: '確認密碼' } } },
        { field: 'Roles', title: '角色設定', span: 24, itemRender: { name: '$checkbox', options: [] } },
        { field: 'OnlineString', title: '是否在線', span: 12, itemRender: { name: '$input', props: { placeholder: '是否在線', disabled: 'true' } } },
        { field: 'LoginIp', title: 'IP位址', span: 12, itemRender: { name: '$input', props: { placeholder: 'IP位址', disabled: 'true' } } },
        { field: 'LoginTimeString', title: '最後登入時間', span: 12, itemRender: { name: '$input', props: { placeholder: '登入時間', disabled: 'true' } } },
        { field: 'LogoutTimeString', title: '登出時間', span: 12, itemRender: { name: '$input', props: { placeholder: '登出時間', disabled: 'true' } } },
        { field: 'Remark', title: '備註', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '內容' } } },
        { align: 'right', span: 24, titleAlign: 'left', itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確認', status: 'primary' } } , { props: { type: 'reset', content: '重置' } }] } } //, events: { click: save }
      ] as VxeFormPropTypes.Items
    });

    const passwordCheck = (val: boolean) => {
      form.formRules['Password'][0].required = val
      form.formRules['Password2'][0].required = val
    }
    
    const getCategories = () => {
        return new Promise((resolve, reject) => {
          model?.dispatch('role/query', { sortings: [ new Sorting('CreatedTime', SortOrder.Descending) ], simple: true })
          .then(response => {
            resolve(response)
          })
          .catch(function (error) {
            reject(error)
            
          });
      });
    };

    onMounted(() => {
      const userStatusEnum: any = Object.values(model!.enums.UserStatus).map(e => { return { label: e.Name, value: e.Value } })
      var item = form.formItems[1];          
      if (item.itemRender && userStatusEnum.length > 0) {            
        form.StatusList = userStatusEnum; 
        item.itemRender.options = userStatusEnum;                  
      }

      getCategories().then((data: any) =>{
        var item = form.formItems[6];          
        if (item.itemRender && data) {            
          form.RolesList = data; 
          item.itemRender.options = data;
        }
      });
    })

    return {
      form,
      grid,
      gridOptions,
      loading,
      isEditing,
      editingRow,
      isRowSelected,
      keyword,
      customFilters,
      submitSave,
      passwordCheck
    };
  },
  methods: {
    reset() {
      this.form.formData.Id = "00000000-0000-0000-0000-000000000000",
      this.form.formData.CreatedTimeString = '';
      this.form.formData.CreatedTime ='';
      this.form.formData.Name = '';
      this.form.formData.Email = '';
      this.form.formData.Status = 0;
      this.form.formData.StatusString = '';
      this.form.formData.Account = '';
      this.form.formData.Password = '';
      this.form.formData.Password2 = '';
      this.form.formData.Remark = ''; 
      this.form.formData.OnlineString = '';
      this.form.formData.LoginTimeString = '';
      this.form.formData.LogoutTimeString = '';
      this.form.formData.LoginIp = '';
      this.form.formData.Roles = [];
      this.form.formData.PhotoId = 0;
      this.form.formData.Photo = {};
    },
    edit(row: any) {
      this.$model.dispatch('user/find', row.Id).then(
        paylod => {          
          Object.assign(this.form.formData, paylod)          
          this.form.model = 'edit';
          this.form.showEdit = true;
          this.form.formData.Password = '';
          this.form.formData.Password2 = '';
          this.form.formData.Roles = paylod.RoleIds;          
          this.passwordCheck(false);
        },
        failure => this.$send('error', { subject: '操作失敗！', content: failure.message })
      );
    },
    addNewRow() {
      this.reset();
      this.form.model = 'insert';
      this.form.showEdit = true;
      this.passwordCheck(true);
    },
    save(row?: any) {
      const { insertRecords, updateRecords, removeRecords } = this.grid.getRecordset();
      let needSave = insertRecords.length || updateRecords.length || removeRecords.length;
      needSave &&= row && (insertRecords.some(e => e._XID === row._XID) || updateRecords.some(e => e._XID === row._XID) || removeRecords.some(e => e._XID === row._XID));   
      const isRowEditing = row && this.editingRow === row;
      if (needSave) {
        this.grid.commitProxy('save').then(
          () => {
            if (isRowEditing && this.editingRow === row) this.edit(row);
            else this.editingRow = null;
          }
        );
      } else if (!row || row === this.editingRow) this.editingRow = null;
    },
    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch('user/delete', row.Id).then(
          () => { this.grid.commitProxy('query').finally(() => { this.gridOptions.loading = false; }); },
          failure => this.$send('error', { subject: '使用者刪除失敗', content: failure.message })
        );
      }
    },
    removeSelectedRows() {
      cash('#batch-dropdown').dropdown('hide');
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy('delete');
      }
    },
    changeFilterEvent(event: Event, option: VxeColumnPropTypes.Filter, panel: any, toogleChecked?: boolean) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value) option.value = new Condition('Name', Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked) panel.changeOption(event, !!option.data, option)
    },   
    async onExport(type: 'csv' | 'html', mode: 'current' | 'selected' | 'all' = 'all') {
      const config: any = { ...this.gridOptions.exportConfig, type, mode };
      if (mode === 'all') config.data = await this.$model.dispatch('user/query');
      this.grid.exportData({ ...config, type, mode });
    },
    async onPrint(mode: 'current' | 'selected' | 'all' = 'all') {
      const config: any = { ...this.gridOptions.printConfig, mode };
      if (mode === 'all') config.data = await this.$model.dispatch('user/query');
      this.grid.print(config);
    },
  }
});
