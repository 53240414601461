
import { defineComponent, ref, PropType } from "@cloudfun/core";
import FileUpload, { VueUploadItem } from 'vue-upload-component';
import Jimp from 'jimp';

export default defineComponent({
  components: {
    FileUpload,
  },
  props: {
    id: String,
    name: String,
    title: String,
    multiple: Boolean,
    droppable: { type: Boolean, default: true },
    accept: String,
    extensions: [Array, String, RegExp],
    previewable: { type: Boolean, default: true },
    listable: { type: Boolean, default: true },
    limitedWidth: { type: Number, default: 0 },
    limitedHeight: { type: Number, default: 0 },
    maximumSize: { type: Number, default: 0 },
    timeout: { type: Number, default: 0 },
    maximumCount: Number,
    action: { type: [String, Promise] },
    headers: Object,
    parameters: Object,
    modelValue: { type: Array as PropType<Array<File | any>>, default: [] },
  },
  setup(props) {
    const instance = ref<any>({});
    const previewSrc = ref('');
    const files = ref([...props.modelValue]);
    return {
      instance,
      previewSrc,
      files,
      loading: ref(false),
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    formatNumber(number: number) {
      let count = 0;
      while (number > 1000 && count < 9) {
        number /= 1000;
        count++;
      }
      let formatedNumber = '' + number.toFixed(2);
      if (formatedNumber.endsWith('.00')) formatedNumber = formatedNumber.substr(0, formatedNumber.length-3);
      switch (count) {
        case 1: return `${formatedNumber}K`;
        case 2: return `${formatedNumber}M`;
        case 3: return `${formatedNumber}G`;
        case 4: return `${formatedNumber}T`;
        case 5: return `${formatedNumber}P`;
        case 6: return `${formatedNumber}E`;
        case 7: return `${formatedNumber}Z`;
        case 8: return `${formatedNumber}Y`;
        default: return `${formatedNumber}`
      }
    },
    filter(current: any, original: any, prevent: (prevent: boolean) => boolean) {
      this.$emit('filter', current, original, prevent);
    },
    input(current: any, original: any) {
      const URL = window.URL || window.webkitURL
      if (!URL || !URL.createObjectURL) return;
      this.$emit('input', current, original);
      // revoke object url
      if (original?.file?.url && (current?.file?.url !== original.file.url)) URL.revokeObjectURL(original.file.url);
      // add file
      if (current && !original) {
        let objectUrl = URL.createObjectURL(current.file);
        if (current?.file?.type && typeof current.file.type.startsWith && current.file.type.startsWith('image/')) {
          const image = new Image();
          image.src = objectUrl;
          current.file.url = objectUrl;
          this.$emit('load', current);
          this.loading = true;
          image.onload = () => {
            if (this.limitedWidth && image.width !==  this.limitedWidth || this.limitedHeight && image.height !== this.limitedHeight) {
              Jimp.read(URL.createObjectURL(current.file)).then(jimp => {
                jimp.resize(this.limitedWidth || Jimp.AUTO, this.limitedHeight || Jimp.AUTO);
                jimp.getBufferAsync(jimp.getMIME()).then(buffer => {
                  current.file = new Blob([buffer], { type: jimp.getMIME() });
                  objectUrl = URL.createObjectURL(current.file);
                  current.file.url = objectUrl;
                  this.previewSrc = objectUrl;
                  this.loading = false;
                  this.$emit('update:modelValue', this.files);
                });
              });
            } else {
              this.previewSrc = objectUrl;
              this.loading = false;
              this.$emit('update:modelValue', this.files);
            }
          }
          image.onerror = (event) => { 
            this.loading = false;
            this.$emit('error', event);
          }
        }
      }
    },
    preview(file?: VueUploadItem) {
      this.previewSrc = (file?.file as any)?.url;
    },
    removeFile(file: VueUploadItem) {
      this.instance.remove(file);
      this.files = this.files.filter(e => e !== file);
      if (this.previewSrc === (file.file as any)?.url) {
        this.preview(this.files.length ? this.files[0] : undefined);
      }
    },
    upload() {
      if (this.files.length) {
        const files = [...this.files];
        return Promise.all(files.filter(e => e.active).map(file => new Promise<VueUploadItem>((resolve, reject) => {
          this.instance.upload(file).then(
            (response: VueUploadItem) => { 
              this.instance.update(file, { active: false, success: !file.error });
              response.active = false;
              response.success = true;
              this.$emit('uploaded', response);
              resolve(response);
            },
            (error:any) => {  
              this.instance.update(file, { active: false, success: false, error: error.message || error.code || error.error || error });
              this.$emit('uploadError', file, error);
              reject(error);
            }
          );
        })));
      }
    },
  }
});
