<template>
  <div>
    <div class="flex container w-full text-center">
      <file-upload
        id="uploader"
        ref="instance"
        class="w-full"
        :input-id="id"
        :name="name"
        :title="title"
        :multiple="multiple"
        :drop="droppable"
        :accept="accept"
        :extensions="extensions"
        :previewable ="previewable"
        :listable="listable"
        :limitedWidth="limitedWidth"
        :limitedHeight="limitedHeight"
        :size="maximumSize"
        :timeout="timeout"
        :maximum="maximumCount"
        :post-action="typeof action === 'string' ? action : undefined"
        :custom-acton="typeof action === 'string' ? undefined : action"
        :headers="headers"
        :data="parameters"
        v-model="files"
        @input-filter="filter"
        @input-file="input"
      >
        <div class="rounded-t bg-transparent-pattern h-48 flex">
          <img v-if="!loading && previewSrc" class="rounded-t m-auto h-48 object-scale-down" :src="previewSrc" />
          <span v-else-if="!loading && limitedWidth || CheckBoxListItem" class="font-semibold text-5xl text-gray-600 text-opacity-80 m-auto">
            {{`${limitedWidth || '&infin;'} x ${limitedHeight || '&infin;'}`}}
          </span>
          <FontAwesome v-else-if="!loading" class="mx-auto h-48" icon="camera" type="fas" />
          <LoadingIcon v-else-if="loading" icon="oval" class="m-auto w-8 h-8" />
        </div>
        <div 
          v-show="!files.length"
          class="text-xs font-semibold py-1 px-1 rounded-b text-white bg-gray-500"
        >
          {{ `${instance.dropActive ? '' : '點選或'}拖曳檔案至此` }}
        </div>
      </file-upload>
    </div>
    <ul class="container w-full" v-show="files.length">
      <li v-for="file in files" :key="file.id" class="text-center relative" @click="preview(file)">
        <div :class="`w-full overflow-hidden h-1 text-xs flex rounded bg-${ file.error ? 'red' : 'blue'}-100`">
          <div 
            :style="{ width: `${file.progress}%` }" 
            :class="`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${ file.error ? 'red' : 'blue' }-400`"
          >
          </div>
        </div>
        <div class="flex w-full items-center justify-between">
          <span :class="`w-12 text-xs font-semibold py-1 px-1 rounded-bl text-white bg-${ file.error ? 'red' : 'blue' }-400`">
            {{ file.success ? '成功' : file.error ? '失敗' : file.active ? '上傳' : '閒置' }}
          </span>
          <span class="w-full text-xs font-semibold pl-1 text-left truncate">
            {{ `${file.name}` }}
          </span>
          <span v-if="file.active || file.success" :class="`w-18 text-xs font-semibold text-${ file.error ? 'red' : 'blue' }-400`">
            {{ `${formatNumber(file.speed)}Bps` }}
          </span>
          <button v-else type="button" @click.stop="removeFile(file)"><FontAwesome class="w-6 h-6 text-red-500" icon="minus-square" type="fas" /></button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from "@cloudfun/core";
import FileUpload, { VueUploadItem } from 'vue-upload-component';
import Jimp from 'jimp';

export default defineComponent({
  components: {
    FileUpload,
  },
  props: {
    id: String,
    name: String,
    title: String,
    multiple: Boolean,
    droppable: { type: Boolean, default: true },
    accept: String,
    extensions: [Array, String, RegExp],
    previewable: { type: Boolean, default: true },
    listable: { type: Boolean, default: true },
    limitedWidth: { type: Number, default: 0 },
    limitedHeight: { type: Number, default: 0 },
    maximumSize: { type: Number, default: 0 },
    timeout: { type: Number, default: 0 },
    maximumCount: Number,
    action: { type: [String, Promise] },
    headers: Object,
    parameters: Object,
    modelValue: { type: Array as PropType<Array<File | any>>, default: [] },
  },
  setup(props) {
    const instance = ref<any>({});
    const previewSrc = ref('');
    const files = ref([...props.modelValue]);
    return {
      instance,
      previewSrc,
      files,
      loading: ref(false),
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    formatNumber(number: number) {
      let count = 0;
      while (number > 1000 && count < 9) {
        number /= 1000;
        count++;
      }
      let formatedNumber = '' + number.toFixed(2);
      if (formatedNumber.endsWith('.00')) formatedNumber = formatedNumber.substr(0, formatedNumber.length-3);
      switch (count) {
        case 1: return `${formatedNumber}K`;
        case 2: return `${formatedNumber}M`;
        case 3: return `${formatedNumber}G`;
        case 4: return `${formatedNumber}T`;
        case 5: return `${formatedNumber}P`;
        case 6: return `${formatedNumber}E`;
        case 7: return `${formatedNumber}Z`;
        case 8: return `${formatedNumber}Y`;
        default: return `${formatedNumber}`
      }
    },
    filter(current: any, original: any, prevent: (prevent: boolean) => boolean) {
      this.$emit('filter', current, original, prevent);
    },
    input(current: any, original: any) {
      const URL = window.URL || window.webkitURL
      if (!URL || !URL.createObjectURL) return;
      this.$emit('input', current, original);
      // revoke object url
      if (original?.file?.url && (current?.file?.url !== original.file.url)) URL.revokeObjectURL(original.file.url);
      // add file
      if (current && !original) {
        let objectUrl = URL.createObjectURL(current.file);
        if (current?.file?.type && typeof current.file.type.startsWith && current.file.type.startsWith('image/')) {
          const image = new Image();
          image.src = objectUrl;
          current.file.url = objectUrl;
          this.$emit('load', current);
          this.loading = true;
          image.onload = () => {
            if (this.limitedWidth && image.width !==  this.limitedWidth || this.limitedHeight && image.height !== this.limitedHeight) {
              Jimp.read(URL.createObjectURL(current.file)).then(jimp => {
                jimp.resize(this.limitedWidth || Jimp.AUTO, this.limitedHeight || Jimp.AUTO);
                jimp.getBufferAsync(jimp.getMIME()).then(buffer => {
                  current.file = new Blob([buffer], { type: jimp.getMIME() });
                  objectUrl = URL.createObjectURL(current.file);
                  current.file.url = objectUrl;
                  this.previewSrc = objectUrl;
                  this.loading = false;
                  this.$emit('update:modelValue', this.files);
                });
              });
            } else {
              this.previewSrc = objectUrl;
              this.loading = false;
              this.$emit('update:modelValue', this.files);
            }
          }
          image.onerror = (event) => { 
            this.loading = false;
            this.$emit('error', event);
          }
        }
      }
    },
    preview(file?: VueUploadItem) {
      this.previewSrc = (file?.file as any)?.url;
    },
    removeFile(file: VueUploadItem) {
      this.instance.remove(file);
      this.files = this.files.filter(e => e !== file);
      if (this.previewSrc === (file.file as any)?.url) {
        this.preview(this.files.length ? this.files[0] : undefined);
      }
    },
    upload() {
      if (this.files.length) {
        const files = [...this.files];
        return Promise.all(files.filter(e => e.active).map(file => new Promise<VueUploadItem>((resolve, reject) => {
          this.instance.upload(file).then(
            (response: VueUploadItem) => { 
              this.instance.update(file, { active: false, success: !file.error });
              response.active = false;
              response.success = true;
              this.$emit('uploaded', response);
              resolve(response);
            },
            (error:any) => {  
              this.instance.update(file, { active: false, success: false, error: error.message || error.code || error.error || error });
              this.$emit('uploadError', file, error);
              reject(error);
            }
          );
        })));
      }
    },
  }
});
</script>
