
/* eslint-disable */

import context from "@cloudfun/core";
import { defineComponent, ref, reactive } from "vue";
import { mapGetters } from "vuex";
import { VxeFormPropTypes } from "vxe-table";

export default defineComponent({
  setup() {
    const searchDropdown = ref(false);
    const model = CloudFun.current?.model;

    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };

    const hideSearchDropdown = () => {
      searchDropdown.value = false;
    };

    const helperModal = reactive({
      showEdit: false,
      link: 'https://www.cloudfun.com.tw/#contact'
    })

    const form = reactive({
      showEdit: false,
      data:{
        password: '',
        password2: '',
      },
      formRules: {              
        password: [{ required: true, message: '請輸入密碼(至少八位英數字、特殊符號)', pattern: new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)") }],
        password2: [{ required: true, message: '請輸入確認密碼(至少八位英數字、特殊符號)', pattern: new RegExp("^((?=.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*|(?=.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[!\\u0022#$%&'()*+,./:;<=>?@[\\]\\^_`{|}~-]).*)") }],        
      } as VxeFormPropTypes.Rules,
      formItems: [
        { field: 'password', title: '密碼', span: 24, itemRender: { name: '$input', props: { type: 'password', placeholder: '密碼' } } },
        { field: 'password2', title: '確認密碼', span: 24, itemRender: { name: '$input', props: { type: 'password', placeholder: '確認密碼' } } },       
        { align: 'right', span: 24, titleAlign: 'left', itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確認', status: 'primary' } } , { props: { type: 'reset', content: '重置' } }] } } //, events: { click: save }
      ] as VxeFormPropTypes.Items
    })

    const openChangePasswordModal = () => {
      form.showEdit = true    
      form.data.password = ''
      form.data.password2 = ''
    }

    return {
      form,
      helperModal,
      openChangePasswordModal,
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      isGuest: !context.current?.user.Id,
    };
  },
  computed: {
    ...mapGetters(['user']),
    homePath() {
      const path = this.$route.path;
      if (path.startsWith('/simple-menu')) return '/simple-menu';
      if (path.startsWith('/top-menu')) return '/top-menu';
      return '/';
    },
  },
  methods: {
    logout() {
      if (!this.$user.Id) {
        this.$router.push('/login')
        return;
      }
      this.$model.dispatch('logout').then(
        () => this.$router.push('/login') 
      );
    },
    submitModal() {
      if(this.form.data.password != this.form.data.password2) {
        CloudFun.send('error', { subject: '操作失敗！', content: '密碼不一致，請確認輸入！' })
        return;
      }      
      this.$model.dispatch(`user/changePassword`, { password: this.form.data.password, key: this.$user.Id  }).then(
        paylod => {         
          CloudFun.send('info', { subject: '更新成功！', content: '密碼更換成功' })
          this.form.showEdit = false;         
        },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure})
      ).finally(() => {
      });      
    },
    helperClick(){
      this.helperModal.showEdit = true;
    }
  },
  watch: {
    user (current) {
      this.isGuest = !current.Id;
    }
  }
});
