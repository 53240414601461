import { Model } from "@cloudfun/core";
import { main, Store as MainStore, State as MainState } from "./main";
import configuration from "./configuration";
import role from "./role";
import user from "./user";
import picture from "./picture";
import pictureAlbum from "./picture-album";
import banner from "./banner";
import bannerPosition from "./banner-position";
import guild from "./guild";
import newsCategory from "./news-category";
import news from "./news";
import linkCategory from "./link-category";
import link from "./link";
import member from "./member";
import actionLog from "./action-log";
import about from "./about";
import forum from "./forum";
import forumReply from "./forumReply";
import forumCategory from "./forumCategory";
import files from "./files";
import industry from "./industry";
import industryCategory from "./industryCategory";
import fileCategory from "./fileCategory";
import book from "./book";
import order from "./order";
import orderItem from "./orderItem";
import classification from "./classification";
import certification from "./certification";

const model = new Model('EmprtProject', { main, configuration, role, user, picture, pictureAlbum, banner, bannerPosition, guild, newsCategory, news, linkCategory, link, member, actionLog, about, forum, forumReply, forumCategory, files, industry, industryCategory, fileCategory, book, order, orderItem, classification, certification });

const backendServer = process.env.VUE_APP_BACKEND_URL;
model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true);
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`);
model.onLogin = (data) => model.clients.unauthorized.post('System/Login', {
    account: data.account, 
    password: data.password, 
    token: data.password,
    LastVisitedUri: data.token,
    LoginIp: data.captcha
});
model.onLogout = () => model.clients.authorized.post('System/Logout');
model.onReloadUser = () => model.clients.authorized.post('System/CurrentUser');
model.onReloadEnums = () => model.clients.unauthorized.get('System/Enumerations');
model.onLog = (message) => model.clients.unauthorized.post('System/Log', message);

export type State = { main: MainState };
export type Store = MainStore<Pick<State, "main">>;

export default model;